import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { DialogService } from 'src/app/dialogservice.service';
import { AgentsService } from 'src/app/utils/services/agents.service';
import { EmitType } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'createAgentDialog',
  templateUrl: './createAgentDialog.component.html',
  styleUrls: ['./createAgentDialog.component.scss'],
})
export class CreateAgentDialogComponent implements OnInit {
  @Input()
  public prefix: string = '';

  @Input()
  public project: any = null as any;

  @Input()
  public statuses: any[] = [];

  @Input()
  public partnerId: string = 'false';

  public salutation: string = 'Herr';
  public firstname: string = '';
  public lastname: string = '';
  public birthdate: any = '';
  public phonenumber: string = '';
  public email: string = '';
  public city: string = '';
  public country: string = '';
  public noticePeriod: string = 'no notice period';
  public experiences: string = '';
  public availableFrom: any = '';
  public status: string = '';
  public interviewDate: string = '';
  public internalNotes: string = '';
  public isPublished: boolean = false;
  public isEditable: boolean = false;
  public profilePhoto: any;
  public agentId: any;
  public isEmailInvalid: boolean = false;
  public defaultProfileImageSrc: string =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvUjKpRjWsF2voE76xA88VIoqx_WemiQxhsQ&usqp=CAU';
  public imageURL: string = '';

  countryCode=[
    "+49",
    "+90",
    "+1",
    "+1-242",
    "+1-246",
    "+1-264",
    "+1-268",
    "+1-284",
    "+1-340",
    "+1-345",
    "+1-441",
    "+1-473",
    "+1-649",
    "+1-664",
    "+1-670",
    "+1-671",
    "+1-684",
    "+1-721",
    "+1-758",
    "+1-767",
    "+1-784",
    "+1-787",
    "+1-809",
    "+1-829",
    "+1-849",
    "+1-868",
    "+1-869",
    "+1-876",
    "+1-939",
    "+20",
    "+211",
    "+212",
    "+213",
    "+216",
    "+218",
    "+220",
    "+221",
    "+222",
    "+223",
    "+224",
    "+225",
    "+226",
    "+227",
    "+228",
    "+229",
    "+230",
    "+231",
    "+232",
    "+233",
    "+234",
    "+235",
    "+236",
    "+237",
    "+238",
    "+239",
    "+240",
    "+241",
    "+242",
    "+243",
    "+244",
    "+245",
    "+246",
    "+248",
    "+249",
    "+250",
    "+251",
    "+252",
    "+253",
    "+254",
    "+255",
    "+256",
    "+257",
    "+258",
    "+260",
    "+261",
    "+262",
    "+263",
    "+264",
    "+265",
    "+266",
    "+267",
    "+268",
    "+269",
    "+27",
    "+290",
    "+291",
    "+297",
    "+298",
    "+299",
    "+30",
    "+31",
    "+32",
    "+33",
    "+34",
    "+350",
    "+351",
    "+352",
    "+353",
    "+354",
    "+355",
    "+356",
    "+357",
    "+358",
    "+359",
    "+36",
    "+370",
    "+371",
    "+372",
    "+373",
    "+374",
    "+375",
    "+376",
    "+377",
    "+378",
    "+379",
    "+380",
    "+381",
    "+382",
    "+383",
    "+385",
    "+386",
    "+387",
    "+389",
    "+39",
    "+40",
    "+41",
    "+420",
    "+421",
    "+423",
    "+43",
    "+44",
    "+44-1481",
    "+44-1534",
    "+44-1624",
    "+45",
    "+46",
    "+47",
    "+48",
    "+500",
    "+501",
    "+502",
    "+503",
    "+504",
    "+505",
    "+506",
    "+507",
    "+508",
    "+509",
    "+51",
    "+52",
    "+53",
    "+54",
    "+55",
    "+56",
    "+57",
    "+58",
    "+590",
    "+591",
    "+592",
    "+593",
    "+595",
    "+597",
    "+598",
    "+599",
    "+60",
    "+61",
    "+62",
    "+63",
    "+64",
    "+65",
    "+66",
    "+670",
    "+672",
    "+673",
    "+674",
    "+675",
    "+676",
    "+677",
    "+678",
    "+679",
    "+680",
    "+681",
    "+682",
    "+683",
    "+685",
    "+686",
    "+687",
    "+688",
    "+689",
    "+690",
    "+691",
    "+692",
    "+7",
    "+81",
    "+82",
    "+84",
    "+850",
    "+852",
    "+853",
    "+855",
    "+856",
    "+86",
    "+880",
    "+886",
    "+91",
    "+92",
    "+93",
    "+94",
    "+95",
    "+960",
    "+961",
    "+962",
    "+963",
    "+964",
    "+965",
    "+966",
    "+967",
    "+968",
    "+970",
    "+971",
    "+972",
    "+973",
    "+974",
    "+975",
    "+976",
    "+977",
    "+98",
    "+992",
    "+993",
    "+994",
    "+995",
    "+996",
    "+998"
];
  public agentCountryCode: string = '';
  public fields: { text: string; value: string } = { text: '', value: '' };
  maxDate = new Date();
  noticePeriodData = [{value:'no notice period', text:'keine Kündigungsfrist'},{value:'2 weeks', text:'2 Wochen'},{value:'4 weeks', text:'4 Wochen'},{value:'6 weeks', text:'6 Wochen'}];
  public field: { text: string; value: string } = { text: 'text', value: 'value' };
  salutationData = ['Herr','Frau','Divers'];

  public constructor(
    public dialogService: DialogService,
    private agentsService: AgentsService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<any>
  ) {
    this.dateAdapter.setLocale('de-DE');
  }

  public ngOnInit(): void { }

  validateForm(): boolean {
    return (
      !!this.salutation &&
      !!this.firstname &&
      !!this.lastname &&
      !!this.phonenumber &&
      !!this.email &&
      !this.isEmailInvalid &&
      !!this.city &&
      !!this.country &&
      !!this.noticePeriod &&
      !!this.experiences &&
      !!this.availableFrom &&
      !!this.agentCountryCode &&
      !!this.birthdate
    );
  }

  createAgent(): void {
    if (this.birthdate instanceof Date) {
      this.birthdate.setHours(0, Math.abs(this.birthdate.getTimezoneOffset()));
      this.birthdate = this.birthdate.toISOString().split('T')[0];
    }
    if (this.availableFrom instanceof Date) {
      this.availableFrom.setHours(0, Math.abs(this.availableFrom.getTimezoneOffset()));
      this.availableFrom = this.availableFrom.toISOString().split('T')[0];
    }
    if (this.validateForm()) {
      const projectDetail = {...this.project};
      if(projectDetail.projectThumbnailPhoto){
        delete projectDetail.projectThumbnailPhoto;
      }
      const projectHistoryDetail = {
          id: this.project.id,
          name: this.project.name
      }
      this.agentsService
        .createAgentForProject(
          {
            salutation: this.salutation,
            firstname: this.firstname,
            lastname: this.lastname,
            birthday: this.birthdate !== '' ? this.birthdate : null,
            phoneNumber: `${this.agentCountryCode} ${this.phonenumber}`,
            emailAddress: this.email,
            city: this.city,
            country: this.country,
            noticePeriod: this.noticePeriod,
            experiences: this.experiences,
            availableFrom: this.availableFrom,
            project: projectDetail,
            partnerId: this.partnerId,
            projectHistory: JSON.stringify([projectHistoryDetail])
          },
          this.project?.id
        )
        .subscribe((response) => {
          if(response){
            this.dialogService.showCreateAgentDialog = false;
            const agent: any = response;
            this.agentId = agent.id;
            if (this.profilePhoto) {
              this.uploadProfilePhoto(
                this.profilePhoto,
                this.profilePhoto.type,
                this.agentId
              );
            }
            const status = this.statuses.find(
              (status) => status.name === 'AgentCreatedFilesMissing'
            );
            if (status) {
              status.agents.push({
                id: agent.id,
                name: `${agent.firstname} ${agent.lastname}`,
                profileImage: null,
                city:agent.city,
                country:agent.country,
                status: agent.status,
                videoFiles: agent.videoFiles ?? [],
                voiceFiles: agent.voiceFiles ?? [],
                previousStatus: agent.previousStatus,
                public_id: agent.public_id,
              });
            }
            this.snackBar.open('Agent erfolgreich erstellt!', '', {
              duration: 4000,
              horizontalPosition:'end',
              verticalPosition:  'top',
            });
          }else{
            this.snackBar.open('Es gibt bereits einen Agent mit derselben E-Mail-Adresse oder Telefonnummer.', '', {
              duration: 4000,
              horizontalPosition:'end',
              verticalPosition:  'top',
            });
          }
        });
    } else {
      console.error('Form is not valid');
    }
  }

  cancel(): void {
    this.dialogService.showCreateAgentDialog = false;
  }

  toggleEditable() {
    this.isEditable = !this.isEditable;
    if (this.isEditable) {
      const heading = document.querySelector('.heading h1');
      if (heading) {
        (heading as HTMLElement).focus();
      }
    }
  }

  onBlur(event: any) {
    this.isEditable = false;
    const newName = event.target.innerText;
  }

  chooseProfilePhoto(event: any): void {
    this.profilePhoto = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  uploadProfilePhoto(profilePhoto: any, type: string, agentId: number) {
    let blob: any;
    const reader: any = new FileReader();
    reader.readAsArrayBuffer(profilePhoto);
    reader.onload = () => {
      blob = new Blob([reader.result], { type: profilePhoto.type });
      const formData: any = new FormData();
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const fileName = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

      if (blob instanceof Blob) {
        formData.append('file', blob, fileName);
        formData.append('type', type);
      }
      this.agentsService.setAgentProfilePhoto(agentId, formData).subscribe(
        (res: any) => {
          const status = this.statuses.find(
            (status) => status.name === 'AgentCreatedFilesMissing'
          );
          if (status) {
            status.agents[status.agents.length - 1].profileImage =
              this.agentsService.convertImagetoUrl(
                res.photoBlob
              );
          }
          this.profilePhoto = null;
        },
        (error) => {
          this.profilePhoto = null;
        }
      );
    };
  }

  emailValueChanged(emailAddress: string): void {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.isEmailInvalid = !emailRegex.test(emailAddress);
  }

  public onFiltering: EmitType<FilteringEventArgs>  =  (e: FilteringEventArgs) => {
    let query = new Query();

    if (e.text && e.text.length > 0) {
      // Use "contains" to allow searching anywhere within the country code
      query = query.where('', 'contains', e.text, true); // Case-insensitive
    }

    // Update the data source with the filtered results
    e.updateData(this.countryCode, query);
  };
}
