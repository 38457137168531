<div class="backdrop" id="backdrop"></div>
<div class="AgentView">
  <div class="heading">
    <h1 class="mb-0"><strong>Bewerbung zurückziehen</strong></h1>
  </div>
  <ng-container *ngIf="!isArchived">
    <div class="detailArea">
      <div class="richText222">
        <div>
          <span style="color: rgb(102, 102, 102)">Wollen Sie wirklich Ihre Bewerbung zurückziehen?</span>
        </div>
      </div>
    </div>

    <div class="bottomRow">
      <button #thisbutton_cssbutton type="button" class="cssButton" (click)="withdrawApplication()">
        <div class="focus:outline-0 bg-transparent">
          <span> Ja </span>
        </div>
      </button>
      <button #thisbutton_cssbutton2 type="button" class="cssButton2" (click)="isFeedback=false;dialogService.showWithdrawApplicationDialog = false;feedback=''">
        <div class="focus:outline-0 bg-transparent">
          <span> Nein </span>
        </div>
      </button>
    </div>
  </ng-container>
  <div class="row feebBack-block" *ngIf="isFeedback">
    <div class="label mb-2">
      <span>Damit wir unsere Prozesse verbessern können, geben Sie uns bitte kurzes Feedback</span>
    </div>
    <div class="text-area">
      <textarea rows="8" [(ngModel)]="feedback"></textarea>
    </div>
    <div class="error" *ngIf="feedback==''&&isFeedbackSubmitted">
      Bitte geben Sie uns Feedback.
    </div>
    <div class="sending-btn">
      <button type="button" class="cssButton" (click)="sendingFeedback()">
        <div class="focus:outline-0 bg-transparent">
          <span> Absenden </span>
        </div>
      </button>
    </div>
  </div>
</div>

