<div class="app">
  <div class="heading">
    <h2>Archiv</h2>
    <mat-form-field appearance="outline" class="dense-4 cust-outline-on-focus mat-form-field-no-padding" style="width: 25%;">
      <mat-icon matPrefix>search</mat-icon>
      <input type="text" matInput placeholder="Suche" [formControl]="search">
    </mat-form-field>
    <label class="toggle">
      <input class="toggle-checkbox" type="checkbox" [(ngModel)]="isAllAgent" (change)="onToggleSwitch()">
      <div class="toggle-switch"></div>
      <span class="toggle-label">In allen Agenten suchen</span>
    </label>
  </div>
  <div class="mb-4">
    <div class="applicants-details">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th style="width: 16%;">Bewerbungseingang</th>
              <th class="ps-lg-5" style="width: 22%;">Name</th>
              <th style="width: 22%;">Standort</th>
              <th style="width: 40%;">Partner</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let agent of filteredAgents">
              <td style="width: 16%;">
                <p class="mb-0 mt-1">{{ agent.createdAt }}</p>
              </td>
              <td style="width: 22%;">
                <div class="d-flex align-items-center gap-3 mt-1">
                  <div class="applicant-img d-flex align-items-center justify-content-center">
                      @if (agent.profileImageSrc) {
                        <img [src]="agent.profileImageSrc"
                            alt="applicants"
                            class="d-block h-100 w-100 object-fit-cover img-fluid flex-shrink-0">
                      }@else{
                        <img  src="../../assets/Icon avatar 1.svg"
                        class="d-block h-100 w-100 object-fit-cover img-fluid flex-shrink-0">
                      }
                  </div>
                  <p class="mb-0">{{agent.firstname}} {{agent.lastname}}</p>
                </div>
              </td>
              <td style="width: 22%;">
                <p class="mb-0 mt-1">{{agent.city}} {{agent.country}}</p>
              </td>
              <td style="width: 40%;">
                <div class="d-flex align-items-center justify-content-between mt-1">
                  <p class="mb-0">{{agent?.partnerFirstName}} {{agent?.partnerLastName}}</p>
                  <button class="d-flex align-items-center justify-content-center" (click)="onClickAgent(agent)">
                    <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20"
                      xmlns="http://www.w3.org/2000/svg" id="screenshot-bb74426a-280e-8067-8003-ed21d11004ec"
                      version="1.1" viewBox="22899 1213 20 20" height="20">
                      <g id="shape-bb74426a-280e-8067-8003-ed21d11004ec" style="fill:#000000" width="1em" height="1em"
                        rx="0" ry="0">
                        <g id="shape-bb74426a-280e-8067-8003-ed21d1111ecb">
                          <g class="fills" id="fills-bb74426a-280e-8067-8003-ed21d1111ecb">
                            <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.8"
                              d="M22909.000,1218.000L22908.106,1218.871L22911.594,1222.375L22904.000,1222.375L22904.000,1223.625L22911.594,1223.625L22908.106,1227.108L22909.000,1228.000L22914.000,1223.000Z">
                            </path>
                          </g>
                        </g>
                        <g id="shape-bb74426a-280e-8067-8003-ed21d11151d4">
                          <g class="fills" id="fills-bb74426a-280e-8067-8003-ed21d11151d4">
                            <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.8"
                              d="M22909.000,1231.750C22904.168,1231.750,22900.250,1227.832,22900.250,1223.000C22900.250,1218.168,22904.168,1214.250,22909.000,1214.250C22913.832,1214.250,22917.750,1218.168,22917.750,1223.000C22917.744,1227.830,22913.830,1231.744,22909.000,1231.750ZM22909.000,1215.500C22904.858,1215.500,22901.500,1218.858,22901.500,1223.000C22901.500,1227.142,22904.858,1230.500,22909.000,1230.500C22913.142,1230.500,22916.500,1227.142,22916.500,1223.000C22916.495,1218.860,22913.140,1215.505,22909.000,1215.500Z">
                            </path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="dialogService.showAgentView" class="backdrop"></div>
<div class="app-agentview" [ngClass]="{ dataModal: dialogService.showAgentView }">
  <app-agentview [data]="data" *ngIf="dialogService.showAgentView" (onClose)="onClose($event)"></app-agentview>
</div>

@if (isSpinnerActive) {
  <app-spinner></app-spinner>
}
