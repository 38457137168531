import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { RecordingService } from '../utils/services/recording.service';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogService } from '../dialogservice.service';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoRecordingService } from '../utils/services/video-recording.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Agent } from '../utils/models/agent.model';
import { SharedService } from '../utils/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSZip from 'jszip';

@Component({
  selector: 'app-record',
  standalone: false,
  templateUrl: './record.component.html',
  styleUrl: './record.component.scss'
})
export class RecordComponent {

  @ViewChild('videoElement') videoElement: any;
  video: any;
  isPlaying = false;
  displayControls = true;
  isAudioRecording = false;
  isVideoRecording = false;
  audioRecordedTime:any;
  videoRecordedTime:any;
  audioBlobUrl:any;
  videoBlobUrl:any;
  audioBlob:any;
  videoBlob:any;
  audioName:any;
  videoName:any;
  audioStream:any;
  videoStream: any;
  audioConf = { audio: true}
  videoConf:any = { video: { facingMode:"user", width: 320 }, audio: true};
  agentVoicesSubject = new BehaviorSubject([]);
  agentVoices$ = this.agentVoicesSubject.asObservable();
  currentPath = '';
  publicId: string = '';
  isSpinner: boolean = false;
  isVideoRecordingStart: boolean = false;
  mediaStream: MediaStream | undefined;
  @Input() agent:any;

  constructor(
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private audioRecordingService: RecordingService,
    private videoRecordingService: VideoRecordingService,
    private sanitizer: DomSanitizer,
    private readonly http: HttpClient,
    private recordingService: RecordingService,
    private sharedService: SharedService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {

    this.videoRecordingService.recordingFailed().subscribe(() => {
      this.isVideoRecording = false;
      this.ref.detectChanges();
    });

    this.videoRecordingService.getRecordedTime().subscribe((time) => {
      this.videoRecordedTime = time;
      this.ref.detectChanges();
      if (this.videoRecordedTime === '01:01') {
        this.snackBar.open('Videoaufnahmegrenze erreicht. Video wird automatisch gespeichert.', '', {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
        });
        this.stopVideoRecording();
      }
    });

    this.videoRecordingService.getStream().subscribe((stream) => {
      this.videoStream = stream;
      this.ref.detectChanges();
    });

    this.videoRecordingService.getRecordedBlob().subscribe((data) => {
      this.videoBlob = data.blob;
      this.videoName = data.title;
      this.videoBlobUrl = this.sanitizer.bypassSecurityTrustUrl(data.url);
      this.ref.detectChanges();
      this.sendToBackend(data, 'Video');
    });

    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isAudioRecording = false;
      this.ref.detectChanges();
 });

    this.audioRecordingService.getRecordedTime().subscribe((time:any) => {
      this.audioRecordedTime = time;
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data:any) => {
      this.audioBlob = data.blob;
      this.audioName = data.title;
      this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      this.ref.detectChanges();
      this.sendToBackend(data, 'Audio');
    });
  }

  ngOnInit() {
    this.currentPath = this.router.url;
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.publicId = params['publicId'];
    });
  }

  ngAfterViewInit() {

  }

  startVideoRecording() {
    if (this.isVideoRecording) {
      this.isVideoRecordingStart = true;
      this.recordingService.isRecordingSubject.next(true);
      this.videoRecordingService.startRecording(this.mediaStream);

    }
  }

  abortVideoRecording() {
    if (this.isVideoRecording) {
      this.isVideoRecording = false;
      this.videoRecordingService.abortRecording();
      this.video.controls = false;
      this.isVideoRecordingStart = false;
    }
  }

  stopVideoRecording() {
    if (this.isVideoRecording) {
      this.isSpinner = true;
      this.videoRecordingService.stopRecording();

      // this.videoRecordingService.getRecordedBlob().subscribe((data) => {
      //   const videoBlob = data;
      //   this.sendToBackend(videoBlob, 'Video');
      // });
      // this.video.srcObject = this.videoBlobUrl;
      this.isVideoRecording = false;
      this.recordingService.isRecordingSubject.next(false);
      this.video.controls = true;
      this.isVideoRecordingStart = false;
    }
  }

  clearVideoRecordedData() {
    this.videoBlobUrl = null;
    this.video.srcObject = null;
    this.video.controls = false;
    this.ref.detectChanges();
  }

  downloadVideoRecordedData() {
    this._downloadFile(this.videoBlob, 'video/mp4', this.videoName);
  }

  startAudioRecording() {
    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this.recordingService.isRecordingSubject.next(true);
      this.audioRecordingService.startRecording()?.then(()=>{
        console.log('startAudioRecording')
      }).catch((error)=>{
        this.isAudioRecording = false;
      this.recordingService.isRecordingSubject.next(false);
      });
    }
  }

  abortAudioRecording() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopAudioRecording() {
    this.isSpinner = true;
    if (this.isAudioRecording) {
      this.audioRecordingService.stopRecording();
      // this.audioRecordingService.getRecordedBlob().subscribe((data: Blob) => {
      //   const audioBlob = data;
      //   this.sendToBackend(audioBlob, 'Audio');
      // });
      this.recordingService.isRecordingSubject.next(false);
      this.isAudioRecording = false;
    }
  }

  async sendToBackend(blob: any, type: string) {
    const formData = new FormData();
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const fileName = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}.${type=='Audio'?'wav':'mp4'}`;

    if (blob.blob instanceof Blob) {
      const zip = new JSZip();
      zip.file(type + '_' + fileName, blob.blob);
      const zippedBlob = await zip.generateAsync({ type:'blob' });

      formData.append('file', type === 'Audio' ? blob.blob : zippedBlob, type + '_' + fileName);
      formData.append('type', type);
    } else {
      console.error('Invalid data type for upload. Expected Blob.');
    }

    this.recordingService.uploadRecording(this.publicId, formData, type).subscribe((res: any) => {
      if (res) {
        if (type === 'Video') {
          this.agent.videoFiles.push(res);
        } else {
          this.agent.voiceFiles.push(res);
        }
        this.sharedService.agentVoicesSubject.next(this.agent);
      }
      // this.sharedService.agentVoicesSubject.next(this.agent);
      this.audioBlobUrl = false;
      this.isSpinner = false;
      const text = `${this.agent.firstname} ${this.agent.lastname} agent hat ${type=='Audio'?'Audioaufnahme':'Videoaufzeichnung'} mit Dateiname ${type+'_'+fileName}`;
      this.sharedService.logAgentMoment(this.agent,this.agent.emailAddress,'Agent',text);
    })
  }

  clearAudioRecordedData() {
    this.audioBlobUrl = null;
  }

  downloadAudioRecordedData() {
    this._downloadFile(this.audioBlob, 'audio/mp3', this.audioName);
  }

  ngOnDestroy(): void {
    this.abortAudioRecording();
  }

  _downloadFile(data: any, type: string, filename: string): any {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    //this.video.srcObject = stream;
    //const url = data;
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  videoPerview(){
    var browser = <any>navigator;
    this.isVideoRecording = true;
    setTimeout(()=>{
      this.video = this.videoElement?.nativeElement;
      this.video.controls = false;
      this.video.muted = true;
      browser.mediaDevices.getUserMedia(this.videoConf).then((stream: any) => {
        this.mediaStream = stream;
        if (this.video) {
          this.video.srcObject = stream;
          this.video.play();
        }
      }).catch((error: any) => {
        console.error('Error accessing media devices.', error);
        this.snackBar.open('Aufnahmegerät nicht gefunden!', '', {
          duration: 4000,
          horizontalPosition:'end',
          verticalPosition:  'top',
        });
        this.isVideoRecording = false;
        this.isVideoRecordingStart = false;
      });
    },1)
  }

}
