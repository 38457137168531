import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ProjectDataCacheService } from 'src/app/project-data-cache.service';
import { DialogService } from '../dialogservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from '../utils/services/projects.service';
import { AgentsService } from '../utils/services/agents.service';
import { KeycloakService } from 'keycloak-angular';
import { BookableTimeSlotsService } from '../utils/services/bookable-time-slots.service';
import { SharedService } from '../utils/services/shared.service';
import { Agent } from '../utils/models/agent.model';
import { RecordingService } from '../utils/services/recording.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from '../utils/services/message.service';
import { Socket } from 'ngx-socket-io';
import { PartnersService } from '../utils/services/partners.service';

@Component({
  selector: 'app-singleProjectView',
  templateUrl: './singleProjectView.component.html',
  styleUrls: ['./singleProjectView.component.scss'],
})
export class SingleProjectViewComponent {
  @Input()
  public prefix: string = '';

  //@Input()
  public project: any = null;

  // get id from route
  public id: number = -1;
  public partnerName = '';

  public agentId = -1;
  public isModal = true;
  public isMediaPlay: boolean = false;

  public unreadMessagesCount: Map<number, number> = new Map<number, number>();
  public readSub?: Subscription;
  public unreadMessagesCountSub?: Subscription;

  public statuses = [
    {
      name: 'AgentCreatedFilesMissing',
      display: 'Neuer Agent',
      roles: ['Partner'],
      agents: [
        {
          id: 0,
          name: 'Agent anlegen',
          status: 'AgentCreatedFilesMissing',
          voiceFiles: [],
          videoFiles: [],
          previousStatus: 'AgentCreatedFilesMissing',
        },
      ],
      collapsed: false,
    },
    {
      name: 'ApplicantGetsChecked',
      display: 'In Prüfung',
      roles: ['Partner'],
      agents: [],
      collapsed: false,
    },
    {
      name: 'WaitingForAgent',
      display: 'Warte auf Agent / voicefile',
      roles: ['Partner'],
      agents: [],
      collapsed: false,
    },
    {
      name: 'ApplicantGetsSuggested',
      display: 'Dem Kunden vorgeschlagen',
      customerDisplayName: 'Neue Bewerber',
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
    {
      name: 'ApplicantRejected',
      display: 'Negative Auswahl',
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
    {
      name: 'AppointmentScheduled',
      display: 'Termin gewünscht / vereinbart',
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
    {
      name: 'ApplicantAccepted',
      display: 'Positive Auswahl',
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
    {
      name: 'ApplicantConfirmed',
      display: 'Zusage',
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
  ];

  public currentRole: string = '';
  public isByPartner: string = 'false';
  public partnerId: string = '';
  public data: any = {};
  public scheduledCount: number = 0;
  public currentUser: string = '';
  public terminData: any;
  public bookedTimeSlots: any;
  public selectedVideoUrl: any;
  public selectedAudioUrl: any;
  public isAdminLoggedIn = false;
  public agents: Agent[] = [];
  public userStatus: any = [];
  subscription: Subscription = new Subscription();
  isSpinnerActive: boolean = false;
  isProjectDataFetched: boolean = false;

  public constructor(
    public readonly projectDataCacheService: ProjectDataCacheService,
    public dialogService: DialogService,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private router: Router,
    private agentsService: AgentsService,
    private keyCloak: KeycloakService,
    private btsService: BookableTimeSlotsService,
    private sharedService: SharedService,
    private recordingService: RecordingService,
    private snackBar: MatSnackBar,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly messageService: MessageService,
    private socket: Socket,
    private readonly partnersService: PartnersService
  ) {
    this.id = parseInt(this.route.snapshot.paramMap.get('id') ?? '-1');
    if (this.id) {
      this.projectsService.getProjectById(this.id).subscribe({
        next: (project) => {
          this.project = project;
        },
        error: (err) => {
          if (err.error.error == 'Not Found') {
            this.router.navigateByUrl('/');
          }
        },
      });
    }
    this.agentVoiceFileStatusChange();
  }

  public ngOnInit(): void {
    const tokenData = this.keyCloak.getKeycloakInstance().tokenParsed;
    this.currentUser = tokenData?.['email'];
    this.partnerId = tokenData?.['sub'] || '';
    const userRoles = tokenData?.realm_access?.roles;

    if (userRoles?.includes('Client')) {
      this.currentRole = 'Client';
      this.partnerId = '';
    } else if (userRoles?.includes('Partner')) {
      this.currentRole = 'Partner';
      this.isByPartner = 'true';
    } else if (userRoles?.includes('Admin')) {
      this.currentRole = 'Admin';
      this.partnerId = '';
      this.isAdminLoggedIn = true;
    }

    if (this.partnerId != '') {
      this.getPartnerInfo(this.partnerId);
    }

    if (this.currentRole != 'Admin')
      this.statuses = this.statuses.filter((status) =>
        status.roles.includes(this.currentRole)
      );
    this.getUserStatusByRole();

    this.sharedService.onFileAccepted.subscribe((res) => {
      this.onFileaccepted(res);
    });
    this.sharedService.onFileRejected.subscribe((res) => {
      this.onFilerejected(res);
    });

    this.subscription = this.sharedService.updatescheduledCountSubject.subscribe(value => {
      this.updatescheduledCount();
    });

    this.getProjectData();

    this.route.paramMap.subscribe(params => {

      this.id = parseInt(this.route.snapshot.paramMap.get('id') ?? '-1');
      if (this.id) {
        this.projectsService.getProjectById(this.id).subscribe({
          next: (project) => {
            this.project = project;
          },
          error: (err) => {
            if (err.error.error == 'Not Found') {
              this.router.navigateByUrl('/');
            }
          },
        });
      }
      this.agentVoiceFileStatusChange();

      this.getProjectData();
    });

    this.partnersService.fetchAll(() => {
      this.partnerName = this.partnersService.partners.find(partner => partner.userId === this.partnerId)?.partnerName ?? '';
      this.getProjectData();
    });
  }

  getProjectData() {
    this.agentsService
    .getAgentsByProjectIdAndPartnerName(this.id, this.partnerName)
    .subscribe((agents) => {
      this.agents = agents;
      this.statuses.forEach((status) => {
        status.agents = agents
          .filter(
            (agent) => agent.status === status.name && agent.id !== undefined
          )
          .map((agent: any) => ({
            id: agent.id ?? -1,
            name: `${agent.firstname} ${agent.lastname}`,
            profileImage: agent.agentProfilePhoto
              ? this.agentsService.convertImagetoUrl(
                  agent.agentProfilePhoto?.photoBlob
                )
              : null,
            status: agent.status,
            city: agent.city,
            country: agent.country,
            videoFiles: agent.videoFiles,
            voiceFiles: agent.voiceFiles,
            interviewAppointment: agent.interviewAppointment,
            previousStatus: agent.previousStatus,
            public_id: agent.public_id,
          }));
        if (status.name === 'AgentCreatedFilesMissing') {
          status.agents.unshift({
            id: 0,
            name: 'Agent anlegen',
            status: 'AgentCreatedFilesMissing',
            voiceFiles: [],
            videoFiles: [],
            previousStatus: 'AgentCreatedFilesMissing',
          });
        }

        if (status.display === 'Termin gewünscht / vereinbart') {
          this.terminData = this.statuses.find(
            (status) => status.name === 'AppointmentScheduled'
          );
        }

        for (const agent of this.agents) {
          this.messageService.countUnreadMessages(this.id, agent.public_id!).subscribe((count: any) => {
            this.unreadMessagesCount.set(agent.id!, count);
          });
        }
      });

      this.readSub = this.socket.fromEvent('messagesRead').subscribe((data: any) => {
        this.unreadMessagesCount.set(data.id, 0);
      });

      this.unreadMessagesCountSub = this.socket.fromEvent('unreadMessagesCount').subscribe((data: any) => {
        this.unreadMessagesCount.set(data.id, data.count);
      });

      this.getArchiveAgentByReason();

      // this.btsService
      //   .getAllBookableTimeSlotsByProjectId(this.id)
      //   .subscribe((timeSlots: any) => {
      //     this.bookedTimeSlots = timeSlots;
      //     this.getSchedleDurtion(timeSlots);

      //     const scheduledStatus = {
      //       scheduledCount: this.scheduledCount,
      //       terminAgentsCount: this.terminData.agents.length,
      //     };
      //     this.sharedService.scheduledStatusSubject.next(scheduledStatus);
      //     // this.statuses = timeSlots.timeWindows;
      //   });

      this.updatescheduledCount();
      this.isProjectDataFetched = true;
    });
  }

  public onDropFromActive(event: any): void {
    if (event.data.id != 0) {
      const data: any = event.data;
      const fromStatus = this.statuses.find((s) => s.name === event.from);
      const toStatus: any = this.statuses.find((s) => s.name === event.to);
      if (fromStatus && toStatus) {
        const agentIndex = fromStatus.agents.findIndex(
          (agent) => agent.id === data.id
        );
        if (agentIndex !== -1) {
          const [removedAgent] = fromStatus.agents.splice(agentIndex, 1);
          removedAgent.status = toStatus.name;
          removedAgent.previousStatus = fromStatus.name;
          toStatus.agents.push(removedAgent);
          // message log
          this.logAgentMoment(removedAgent);
        }

        const agentStatus = {
          AgentStatus: toStatus.name,
        };
        this.agentsService
          .updateAgentStatus(data.id, agentStatus)
          .subscribe((res: any) => {
            // ToDo: If Any post request code.
            // if(event.from=='ApplicantAccepted' && event.to=='ApplicantRejected' && this.currentRole==='Admin'){
            //   this.archivAgent(data.id,'ApplicantArchived');
            // }
          });
      }
      if (toStatus?.display == 'Termin gewünscht / vereinbart' || fromStatus?.display == 'Termin gewünscht / vereinbart') {
        this.terminData = this.statuses.find(
          (status) => status.name === 'AppointmentScheduled'
        );
        this.getSchedleDurtion(this.bookedTimeSlots);

        const scheduledStatus = {
          scheduledCount: this.scheduledCount,
          terminAgentsCount: this.terminData.agents.length,
        };
        this.sharedService.scheduledStatusSubject.next(scheduledStatus);
      }
      if (toStatus?.name == 'AppointmentScheduled') {
        this.agentId = data.id;
        let totalSlotDuration = 0;
        this.bookedTimeSlots.forEach((slot: any) => {
          totalSlotDuration += slot.slotDuration;
        });
        const minimumRequiredTime = this.terminData.agents.length * 30;

        if (totalSlotDuration < minimumRequiredTime) {
          this.showTimeSlotDialog();
        }
      }
    }
  }

  navigateToSingleView(id: number, status: any) {
    this.data = {
      agentId: id,
      projectId: this.id,
      coloumnName: status,
    };
    this.dialogService.showAgentView = true;
  }

  showTimeSlotDialog() {
    this.dialogService.showTimeSlotDialog = true;
  }

  showCreateAgentDialog() {
    this.dialogService.showCreateAgentDialog = true;
  }

  getSchedleDurtion(timeSlots: any[]) {
    let slotCount = 0;
    timeSlots.forEach((data) => {
      const today = new Date().setHours(0, 0, 0, 0);
      Object.keys(data.timeWindows).forEach((dayName) => {
        const daySlots = data.timeWindows[dayName];
        const dayDates = this.getDateFromDayName(
          data.fromDate,
          data.toDate,
          dayName
        );
        if (daySlots.length > 0) {
          dayDates.forEach((date: Date) => {
            if (date.getTime() > today) {
              slotCount += daySlots.length;
            }
          });
        }
      });
    });
    this.scheduledCount = slotCount;
    const scheduledStatus = {
      scheduledCount: this.scheduledCount,
      terminAgentsCount: this.terminData.agents.length,
    };
    this.sharedService.scheduledStatusSubject.next(scheduledStatus);
  }

  updatescheduledCount() {
    this.btsService
    .getAllBookableTimeSlotsByProjectId(this.id)
    .subscribe((timeSlots: any) => {
      this.bookedTimeSlots = timeSlots;
      this.getSchedleDurtion(timeSlots);

      const scheduledStatus = {
        scheduledCount: this.scheduledCount,
        terminAgentsCount: this.terminData.agents.length,
      };
      this.sharedService.scheduledStatusSubject.next(scheduledStatus);
      // this.statuses = timeSlots.timeWindows;
    });
    this.changeDetectorRef.detectChanges();
  }

  scheduledDurationChanged(event: any) {
    const index = this.bookedTimeSlots.findIndex((timeSlot: any) => {
      return (
        moment(event.fromDate).isSame(moment(timeSlot.fromDate)) &&
        moment(event.toDate).isSame(moment(timeSlot.toDate))
      );
    });
    if (index >= 0) {
      this.bookedTimeSlots[index] = event;
    } else {
      this.bookedTimeSlots.push(event);
    }
    this.getSchedleDurtion(this.bookedTimeSlots);
  }

  openMediaModal(event: Event, id: number) {
    event.stopPropagation();
    this.getSelectedAgentsSelectedMedia(id);
  }

  getSelectedAgentsSelectedMedia(id: number) {
    this.isSpinnerActive = true;
    const agent: any = this.agents.find((agent) => agent.id === id);
    const videoBlob = agent?.videoFiles.find(
      (video: any) => video.status === 'accepted'
    );
    const voiceBlob = agent?.voiceFiles.find(
      (voice: any) => voice.status === 'accepted'
    );

    if (videoBlob) {
      // this.agentsService.getVideoById(videoBlob.id).subscribe((res: any) => {
        // this.convertToVideoBlobUrl(res.videoBlob.data);
      this.selectedVideoUrl = this.agentsService.getVideoStreamUrl(videoBlob.id);
        if (voiceBlob) {
          // this.agentsService
          //   .getAudioById(voiceBlob.id)
          //   .subscribe((res: any) => {
              // this.convertToAudioBlobUrl(res.voiceBlob.data);
              this.selectedAudioUrl = this.agentsService.getAudioStreamUrl(voiceBlob.id);
              this.isMediaPlay = true;
              this.isSpinnerActive = false;
            // });
        } else {
          this.isMediaPlay = true;
          this.isSpinnerActive = false;
        }
      // });
    } else if (voiceBlob) {
      // this.agentsService.getAudioById(voiceBlob.id).subscribe((res: any) => {
      //   this.convertToAudioBlobUrl(res.voiceBlob.data);
        this.selectedAudioUrl = this.agentsService.getAudioStreamUrl(voiceBlob.id);
        this.isMediaPlay = true;
        this.isSpinnerActive = false;
      // });
    }
    if (!videoBlob && !voiceBlob) {
      this.snackBar.open('Nicht akzeptiert Audiodatei oder Videodatei.', '', {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
      this.isSpinnerActive = false;
    }
  }

  convertToVideoBlobUrl(data: any) {
    const base64String = this.recordingService.arrayToBase64(data);
    const mimeType = 'video/webm';
    this.selectedVideoUrl = this.recordingService.blobToUrl(
      base64String,
      mimeType
    );
  }

  convertToAudioBlobUrl(data: any) {
    const base64String = this.recordingService.arrayToBase64(data);
    const mimeType = 'audio/mpeg';
    this.selectedAudioUrl = this.recordingService.blobToUrl(
      base64String,
      mimeType
    );
  }

  adminSelected(): void {
    this.currentRole = 'Admin';
    this.getUserStatusByRole();
  }

  clientSelected(): void {
    this.currentRole = 'Client';
    this.getUserStatusByRole();
  }

  getUserStatusByRole() {
    if (this.currentRole != 'Admin') {
      this.userStatus = this.statuses.filter((item) =>
        item.roles.includes(this.currentRole)
      );
    } else {
      this.userStatus = this.statuses;
    }
  }

  isStatusIsOpenTask(status: string): boolean {
    return (
      status === 'ApplicantGetsChecked' ||
      status === 'ApplicantGetsSuggested' ||
      status === 'AppointmentScheduled' ||
      status === 'ApplicantAccepted'
    );
  }

  isFilesMissing(videoFiles: any, voiceFiles: any) {
    return videoFiles?.length === 0 && voiceFiles?.length === 0;
  }

  onAgentStatusChange(event: any) {
    let agent: any = this.agents.find((agent) => {
      return agent.id === event.id;
    });
    const fromStatus: any = this.statuses.find(
      (s) => s.name === event.previousStatus
    );
    const toStatus: any = this.statuses.find((s) => s.name === event.status);
    const agentIndex = fromStatus.agents.findIndex(
      (agent: any) => agent.id === event.id
    );

    if (agentIndex !== -1) {
      const [removedAgent] = fromStatus.agents.splice(agentIndex, 1);
      removedAgent.previousStatus = fromStatus.name;
      removedAgent.status = toStatus.name;
      toStatus.agents.push(removedAgent);
      this.logAgentMoment(removedAgent);
    }
    if (agent) {
      agent.status = event.status;
    } else {
      this.agents.push(event);
    }
  }

  onselectInterviewDate(event: any) {
    let agent: any = this.agents.find((agent) => {
      return agent.id === event.id;
    });
    if(agent){
      agent.interviewAppointment = event.interviewAppointment;
    }
    const status:any = this.statuses.find((s) => s.name === event.status);
    const agentIndex = status.agents.findIndex(
      (agent: any) => agent.id === event.id
    );
    if (agentIndex !== -1) {
      status.agents[agentIndex].interviewAppointment = event.interviewAppointment;
    }
  }

  getPartnerInfo(partnerId: any) {
    this.agentsService.getPartnerInfo(partnerId).subscribe((res) => {
      this.project.partnerInfo = res;
    });
  }
  onMoveAgent() {
    this.ngOnInit();
  }

  onClickProjectDesc() {
    this.dialogService.showNewProjectDialog = true;
  }

  getBorderColor(entry: any) {
    let color = '';
    switch (entry.status) {
      case 'AgentCreatedFilesMissing':
        color = 'border-green';
        break;
      case 'ApplicantGetsChecked':
        color = 'border-green';
        break;
      case 'WaitingForAgent':
        if (entry.voiceFiles.length == 0 && entry.videoFiles.length == 0) {
          color = 'border-grey';
        } else if (
          entry.voiceFiles.some((obj: any) => obj.status === 'accepted') ||
          entry.videoFiles.some((obj: any) => obj.status === 'accepted')
        ) {
          color = 'border-green';
        } else {
          color = 'border-red';
        }
        // else if (
        //   entry.voiceFiles.some((obj: any) => obj.isPlayed === false)
        // ) {
        //   color = 'border-red';
        // } else {
        //   color = 'border-green';
        // }
        break;
      case 'ApplicantGetsSuggested':
        color = 'border-green';
        break;
      case 'ApplicantRejected':
        color = 'border-green';
        break;
      case 'AppointmentScheduled':
        if (entry.interviewAppointment) {
          color = 'border-green';
        } else {
          color = 'border-yellow';
        }
        break;
      case 'ApplicantAccepted':
        color = 'border-green';
        break;
      case 'ApplicantConfirmed':
        color = 'border-green';
        break;

      default:
        break;
    }
    return color;
  }

  isPlayIconVisible(entry: any) {
    switch (entry.status) {
      case 'WaitingForAgent':
      case 'ApplicantGetsSuggested':
      case 'ApplicantRejected':
      case 'AppointmentScheduled':
      case 'ApplicantAccepted':
      case 'ApplicantConfirmed':
        return entry.voiceFiles.length > 0 || entry.videoFiles.length > 0;
      default:
        return false;
    }
  }

  isMessageIconVisible(entry: Agent) {
    const count = this.unreadMessagesCount.has(entry.id!) ? this.unreadMessagesCount.get(entry.id!)! : 0;
    return count > 0;
  }

  getPlayIconColor(entry: any) {
    if (entry.voiceFiles.some((obj: any) => obj.status === 'accepted') || entry.videoFiles.some((obj: any) => obj.status === 'accepted')) {
      return '#24b89b';
    } else if (
      entry.voiceFiles.every((obj: any) => obj.status !== 'accepted')
    ) {
      return '#af1c3d';
    } else {
      return '#6c6c6c';
    }
    // if (entry.voiceFiles.some((obj: any) => obj.isPlayed === false)) {
    //   return '#af1c3d';
    // } else if (entry.voiceFiles.every((obj: any) => obj.isPlayed === true)) {
    //   return '#24b89b';
    // } else {
    //   return '#6c6c6c';
    // }
  }

  agentVoiceFileStatusChange() {
    this.subscription = this.sharedService.agentVoiceFile$.subscribe(
      (value: any) => {
        const fromStatus = this.statuses.find((s) => s.name === value.status);
        if (fromStatus) {
          const agentIndex = fromStatus.agents.findIndex(
            (agent) => agent.id === value.id
          );
          if (value.isVoiceFile) {
            fromStatus.agents[agentIndex].voiceFiles.forEach((element: any) => {
              if (element.id == value.fileStatusChangeId) {
                element.isPlayed = true;
              }
            });
          } else {
            fromStatus.agents[agentIndex].videoFiles.forEach((element: any) => {
              if (element.id == value.fileStatusChangeId) {
                element.isPlayed = true;
              }
            });
          }
        }
      }
    );
  }

  getArchiveAgentByReason() {
    const reasons = [
      'rejected by customer for project',
      'Agent canceled or cannot keep an appointment',
    ];
    this.agentsService
      .getArchiveAgentByReasons(reasons, this.id, this.partnerId)
      .subscribe((res: any) => {
        if (res.length) {
          const ArryOfAgents: any = this.statuses.find(
            (status) => status.name == 'ApplicantRejected'
          )?.agents;
          res.forEach((agent: any) => {
            const agentObj = {
              id: agent.id ?? -1,
              name: `${agent.firstname} ${agent.lastname}`,
              profileImage: agent.agentProfilePhoto
                ? this.agentsService.convertImagetoUrl(
                    agent.agentProfilePhoto?.photoBlob
                  )
                : null,
              status: agent.status,
              city: agent.city,
              country: agent.country,
              videoFiles: agent.videoFiles,
              voiceFiles: agent.voiceFiles,
              interviewAppointment: agent.interviewAppointment,
            };
            ArryOfAgents?.push(agentObj);
          });
        }
      });
  }
  archivAgent(agentId: any, AgentStatus: string) {
    const obj = {
      reason: 'Agent canceled or cannot keep an appointment',
      AgentStatus: AgentStatus,
    };
    this.agentsService.archiveAgent(Number(agentId), obj).subscribe(() => {});
  }

  getDateFromDayName(fromDate: any, toDate: any, dayName: any) {
    const dayIndex: any = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    const from = new Date(fromDate);
    const to = new Date(toDate);

    const resultDates = [];

    for (let d = new Date(from); d <= to; d.setDate(d.getDate() + 1)) {
      if (d.getDay() === dayIndex[dayName]) {
        resultDates.push(new Date(d));
      }
    }

    return resultDates;
  }

  onFileaccepted(event: any) {
    const agentStatus: any = this.statuses.find((s) => s.name === event.status);
    const agentIndex = agentStatus.agents.findIndex(
      (agent: any) => agent.id === event.agentId
    );
    if (event.isAudioFile) {
      const audioFileIndex = agentStatus.agents[
        agentIndex
      ].voiceFiles.findIndex((audio: any) => audio.id === event.fileId);
      agentStatus.agents[agentIndex].voiceFiles[audioFileIndex].status =
        'accepted';
    } else {
      const audioFileIndex = agentStatus.agents[
        agentIndex
      ].videoFiles.findIndex((audio: any) => audio.id === event.fileId);
      agentStatus.agents[agentIndex].videoFiles[audioFileIndex].status =
        'accepted';
    }
  }

  onFilerejected(event: any) {
    const agentStatus: any = this.statuses.find((s) => s.name === event.status);
    const agentIndex = agentStatus.agents.findIndex(
      (agent: any) => agent.id === event.agentId
    );
    if (event.isAudioFile) {
      const audioFileIndex = agentStatus.agents[
        agentIndex
      ].voiceFiles.findIndex((audio: any) => audio.id === event.fileId);
      agentStatus.agents[agentIndex].voiceFiles[audioFileIndex].status =
        'rejected';
    } else {
      const audioFileIndex = agentStatus.agents[
        agentIndex
      ].videoFiles.findIndex((audio: any) => audio.id === event.fileId);
      agentStatus.agents[agentIndex].videoFiles[audioFileIndex].status =
        'rejected';
    }
  }

  logAgentMoment(agent: any) {
    let text = `Verschoben von ${
      this.sharedService.statuses[agent.previousStatus].display
    } zu ${this.sharedService.statuses[agent.status].display}`;
    this.sharedService.logAgentMoment(
      { ...agent, project: { id: this.project.id } },
      this.currentUser,
      this.currentRole,
      text
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.readSub?.unsubscribe();
    this.unreadMessagesCountSub?.unsubscribe();
  }

  adjustImage(image: HTMLImageElement): void {
    if (image.naturalWidth === image.naturalHeight) {
      image.style.width = '100%';
      image.style.height = '100%';
    } else if (image.naturalWidth < image.naturalHeight) {
      image.style.width = '100%';
      image.style.height = 'auto';
    } else {
      image.style.width = 'auto';
      image.style.height = '100%';
    }
  }
}
