export const environment = {
  production: true,
  protocol: 'https',
  domain: 'smylo-agent-board.testing.photonic-codes.cloud',
  port: '42080',
  apiPrefix: 'api',
  keycloakUrl: 'https://kc.testing.photonic-codes.cloud',
  keycloakClient: 'smylo-client',
  syncFusionLicenseKey: 'Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhcdXRQRWVeUkV1XEM='
};
