import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ProjectDataCacheService } from 'src/app/project-data-cache.service';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss']
})
export class MainPageComponent implements OnInit {
  @Input()
  public prefix: string = '';
  public currentPath = '';
  public isAgentUser = false;
  public currentUser: string = '';
  public currentRole: string = '';
  public currentName: string = '';
  public isLoggedIn: boolean = false;
  public islogoutPopup: boolean = false;

  public constructor(public readonly projectDataCacheService: ProjectDataCacheService, private route: ActivatedRoute,private router: Router, protected readonly keycloak: KeycloakService) {

  }

  public ngOnInit(): void {
    this.currentPath = this.router.url;
    if (this.currentPath.includes('public')) {
      this.isAgentUser = true;
    }

    const tokenData = this.keycloak.getKeycloakInstance().tokenParsed;
    this.currentUser = tokenData?.['email'];
    this.currentName = tokenData?.['name'];
    const userRoles = tokenData?.realm_access?.roles;

    if (userRoles?.includes('Client')) {
      this.currentRole = "Client";
    } else if (userRoles?.includes('Partner')){
      this.currentRole = "Partner";
    } else if (userRoles?.includes('Admin')) {
      this.currentRole = "Admin";
    }

    this.isLoggedIn = this.keycloak.isLoggedIn();
  }

  onClickLogout(){
    this.keycloak.logout(`${window.location.origin}/logout`);
  }
}
