import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'drag-and-drop-card',
  templateUrl: './drag-and-drop-card.component.html',
  styleUrls: ['./drag-and-drop-card.component.scss']
})
export class DragAndDropCardComponent {
  @Input()
  public data: any;

  @Input()
  public cardTemplate!: TemplateRef<any>;
}
