<div class="App">
  <div class="close-btn ms-auto mb-3" (click)="cancel()" style="margin-right: -8px;">
    <span class="d-block" style="color: rgb(102, 102, 102);">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
        <path
          d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z">
        </path>
      </svg>
    </span>
  </div>
  <div class="ms-auto overflow-y-auto overflow-x-hidden">
    <div class="d-flex align-items-end">
      <div class="profile-img">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvUjKpRjWsF2voE76xA88VIoqx_WemiQxhsQ&usqp=CAU"
        alt="profile img" class="h-100 w-100 d-block">
      </div>
      <!-- <div class="d-flex align-items-center justify-content-center"
        style="gap: 6px; max-width: 188px; width: 100%; padding: 0 6px; border: 1px solid #e0e0e0; border-radius: 5px; cursor: pointer;">
        <span class="fa-solid fa-upload" style="color: #24B89B;"></span>
        <input type="file" class="file-upload" (change)="chooseProfilePhoto($event)" accept="image/*">
      </div> -->
    </div>
    <h1><strong>Agent Anlegen</strong></h1>
    <div class="Layout">
      <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24" xmlns="http://www.w3.org/2000/svg"
        style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51c0" version="1.1"
        viewBox="3666.602 -284.714 24 24" height="24" *ngIf="project?.partnerInfo">
        <g id="shape-34614192-5454-8094-8003-c77b7f5b51c0" style="fill: #000000" width="24" height="24" rx="0" ry="0">
          <g id="shape-34614192-5454-8094-8003-c77b7f5b82b5">
            <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82b5">
              <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                d="M3684.602,-269.714L3682.602,-269.714L3682.602,-267.714L3684.602,-267.714M3684.602,-273.714L3682.602,-273.714L3682.602,-271.714L3684.602,-271.714M3686.602,-265.714L3678.602,-265.714L3678.602,-267.714L3680.602,-267.714L3680.602,-269.714L3678.602,-269.714L3678.602,-271.714L3680.602,-271.714L3680.602,-273.714L3678.602,-273.714L3678.602,-275.714L3686.602,-275.714M3676.602,-277.714L3674.602,-277.714L3674.602,-279.714L3676.602,-279.714M3676.602,-273.714L3674.602,-273.714L3674.602,-275.714L3676.602,-275.714M3676.602,-269.714L3674.602,-269.714L3674.602,-271.714L3676.602,-271.714M3676.602,-265.714L3674.602,-265.714L3674.602,-267.714L3676.602,-267.714M3672.602,-277.714L3670.602,-277.714L3670.602,-279.714L3672.602,-279.714M3672.602,-273.714L3670.602,-273.714L3670.602,-275.714L3672.602,-275.714M3672.602,-269.714L3670.602,-269.714L3670.602,-271.714L3672.602,-271.714M3672.602,-265.714L3670.602,-265.714L3670.602,-267.714L3672.602,-267.714M3678.602,-277.714L3678.602,-281.714L3668.602,-281.714L3668.602,-263.714L3688.602,-263.714L3688.602,-277.714Z">
              </path>
            </g>
          </g>
        </g>
      </svg>
      {{this.project.partnerInfo?.firstName}} {{this.project.partnerInfo?.lastName}}
    </div>
    <!-- <div style="margin-top: 8px;">
      <svg xmlns:xlink="http://www.w3.org/1999/xlink"
        fill="none" width="125" xmlns="http://www.w3.org/2000/svg" style="-webkit-print-color-adjust:exact"
        id="screenshot-34614192-5454-8094-8003-c77b7f5ac69c" version="1.1" viewBox="3666.602 -249.714 125 19"
        height="19">
        <style data-loading="true"> </style>
        <g id="shape-34614192-5454-8094-8003-c77b7f5ac69c" rx="0" ry="0">
          <g id="shape-34614192-5454-8094-8003-c77b7f5b8296">
            <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b8296">
              <rect rx="5" ry="5" style="fill:#fcc419;fill-opacity:1" x="3666.6017007920336" y="-249.71354914285575"
                transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)"
                width="107.75862010454784" height="17.999998557102117"> </rect>
            </g>
          </g>
          <g id="shape-34614192-5454-8094-8003-c77b7f5b8297">
            <defs>
              <pattern patternUnits="userSpaceOnUse" x="3669.601701081824" y="-248.71354914285575"
                height="18.000001523058927" width="121.9999997102077" data-loading="false" id="fill-0-rumext-id-1025">
                <g>
                  <rect style="fill:#FFFFFF;fill-opacity:1" width="121.9999997102077" height="18.000001523058927">
                  </rect>
                </g>
              </pattern>
              <pattern patternUnits="userSpaceOnUse" x="3669.601701081824" y="-248.71354914285575"
                height="18.000001523058927" width="121.9999997102077" data-loading="false" id="fill-1-rumext-id-1025">
                <g>
                  <rect style="fill:#FFFFFF;fill-opacity:1" width="121.9999997102077" height="18.000001523058927">
                  </rect>
                </g>
              </pattern>
              <pattern patternUnits="userSpaceOnUse" x="3669.601701081824" y="-248.71354914285575"
                height="18.000001523058927" width="121.9999997102077" data-loading="false" id="fill-2-rumext-id-1025">
                <g>
                  <rect style="fill:#FFFFFF;fill-opacity:1" width="121.9999997102077" height="18.000001523058927">
                  </rect>
                </g>
              </pattern>
            </defs>
            <g transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)" class="text-container"
              x="3669.601701081824" y="-248.71354914285575" width="121.9999997102077" height="18.000001523058927" rx="0"
              ry="0">
              <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b8297"> <text x="3669.601701081824"
                  y="-233.5920201597396" dominant-baseline="ideographic" textLength="31.232643127441406"
                  lengthAdjust="spacingAndGlyphs"
                  style="text-transform:capitalize;font-family:Roboto;letter-spacing:normal;font-style:normal;font-weight:400;white-space:pre;font-size:12px;text-decoration:none solid rgb(255, 255, 255);direction:ltr;fill:#FFFFFF;fill-opacity:1">Noch
                </text> </g>
              <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b8297"> <text x="3700.8343442092655"
                  y="-233.5920201597396" dominant-baseline="ideographic" textLength="9.531246185302734"
                  lengthAdjust="spacingAndGlyphs"
                  style="text-transform:lowercase;font-family:Roboto;letter-spacing:normal;font-style:normal;font-weight:400;white-space:pre;font-size:12px;text-decoration:none solid rgb(255, 255, 255);direction:ltr;fill:#FFFFFF;fill-opacity:1">ni</text>
              </g>
              <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b8297"> <text x="3710.3655903945682"
                  y="-233.5920201597396" dominant-baseline="ideographic" textLength="58.315975189208984"
                  lengthAdjust="spacingAndGlyphs"
                  style="text-transform:none;font-family:Roboto;letter-spacing:normal;font-style:normal;font-weight:400;white-space:pre;font-size:12px;text-decoration:none solid rgb(255, 255, 255);direction:ltr;fill:#FFFFFF;fill-opacity:1">cht
                  geprüft</text> </g>
            </g>
          </g>
        </g>
      </svg>
    </div> -->
    <div class="contentarea">
      <div class="leftSide">
        <div class="left-side-header">
          Agent Informationen
        </div>
        <div class="Zeile2345">
          <div class="Layout23456">
            <!-- Shape: svg -->
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust:exact" id="screenshot-43c4c9b0-5d53-809a-8003-cb7fdc6a701a"
              version="1.1" viewBox="7920 -2319 20 20" height="20">
              <g id="shape-43c4c9b0-5d53-809a-8003-cb7fdc6a701a" style="fill:#000000" width="24" height="24" rx="0"
                ry="0">
                <g id="shape-43c4c9b0-5d53-809a-8003-cb7fdc6c741b" style="fill:#000000" rx="0" ry="0">
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb7fdc6da8ab">
                    <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb7fdc6da8ab">
                      <path rx="0" ry="0" style="fill:#666666"
                        d="M7934.583,-2309.000C7934.585,-2307.738,7934.065,-2306.532,7933.146,-2305.667C7932.296,-2304.862,7931.170,-2304.415,7930.000,-2304.417C7928.782,-2304.417,7927.675,-2304.892,7926.854,-2305.667C7925.935,-2306.532,7925.415,-2307.738,7925.417,-2309.000Z">
                      </path>
                    </g>
                  </g>
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb7fdc6e8779">
                    <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb7fdc6e8779">
                      <path fill-rule="evenodd" clip-rule="evenodd" rx="0" ry="0" style="fill:#666666"
                        d="M7920.833,-2313.167C7920.833,-2315.928,7923.072,-2318.167,7925.833,-2318.167L7934.167,-2318.167C7936.928,-2318.167,7939.167,-2315.928,7939.167,-2313.167L7939.167,-2304.833C7939.167,-2302.072,7936.928,-2299.833,7934.167,-2299.833L7920.833,-2299.833ZM7923.125,-2309.000C7923.125,-2312.797,7926.203,-2315.875,7930.000,-2315.875C7933.797,-2315.875,7936.875,-2312.797,7936.875,-2309.000C7936.875,-2305.203,7933.797,-2302.125,7930.000,-2302.125C7926.203,-2302.125,7923.125,-2305.203,7923.125,-2309.000Z">
                      </path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            Anrede*
          </div>
          <div class="Layout234567">
            <ejs-dropdownlist [dataSource]='salutationData' [(ngModel)]="salutation" class="outlined-dropdown"></ejs-dropdownlist>
          </div>

        </div>
        <div class="Zeile2345">
          <div class="Layout23456">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="22" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51bc"
              version="1.1" viewBox="3666.602 -154.714 20 20" height="22">
              <g id="shape-34614192-5454-8094-8003-c77b7f5b51bc" style="fill: #000000" width="56" height="56" rx="0"
                ry="0">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82ad">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82ad">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M3676.602,-136.176C3681.264,-136.176,3685.140,-140.043,3685.140,-144.714C3685.140,-149.376,3681.256,-153.251,3676.593,-153.251C3671.923,-153.251,3668.064,-149.376,3668.064,-144.714C3668.064,-140.043,3671.931,-136.176,3676.602,-136.176ZM3676.602,-141.859C3674.082,-141.859,3672.141,-140.964,3671.295,-139.959C3670.173,-141.214,3669.495,-142.880,3669.495,-144.714C3669.495,-148.656,3672.643,-151.829,3676.594,-151.829C3680.536,-151.829,3683.708,-148.656,3683.717,-144.714C3683.717,-142.872,3683.039,-141.206,3681.909,-139.951C3681.063,-140.955,3679.122,-141.859,3676.602,-141.859ZM3676.602,-143.282C3678.209,-143.265,3679.473,-144.638,3679.473,-146.438C3679.473,-148.129,3678.201,-149.535,3676.601,-149.535C3674.994,-149.535,3673.714,-148.129,3673.731,-146.438C3673.739,-144.638,3674.986,-143.299,3676.602,-143.282Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Vorname*
          </div>
          <div class="Layout234567">
            <input #thisinput_firstname [id]="prefix + 'firstname'" class="textInput" type="text" [value]="firstname" style="width: 100%;"
              [(ngModel)]="firstname" (input)="thisinput_firstname.value" [style.backgroundcolor]="'#ffffff'">
          </div>

        </div>
        <div class="Zeile23452">
          <div class="Layout234562">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="22" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51bc"
              version="1.1" viewBox="3666.602 -154.714 20 20" height="22">
              <g id="shape-34614192-5454-8094-8003-c77b7f5b51bc" style="fill: #000000" width="56" height="56" rx="0"
                ry="0">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82ad">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82ad">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M3676.602,-136.176C3681.264,-136.176,3685.140,-140.043,3685.140,-144.714C3685.140,-149.376,3681.256,-153.251,3676.593,-153.251C3671.923,-153.251,3668.064,-149.376,3668.064,-144.714C3668.064,-140.043,3671.931,-136.176,3676.602,-136.176ZM3676.602,-141.859C3674.082,-141.859,3672.141,-140.964,3671.295,-139.959C3670.173,-141.214,3669.495,-142.880,3669.495,-144.714C3669.495,-148.656,3672.643,-151.829,3676.594,-151.829C3680.536,-151.829,3683.708,-148.656,3683.717,-144.714C3683.717,-142.872,3683.039,-141.206,3681.909,-139.951C3681.063,-140.955,3679.122,-141.859,3676.602,-141.859ZM3676.602,-143.282C3678.209,-143.265,3679.473,-144.638,3679.473,-146.438C3679.473,-148.129,3678.201,-149.535,3676.601,-149.535C3674.994,-149.535,3673.714,-148.129,3673.731,-146.438C3673.739,-144.638,3674.986,-143.299,3676.602,-143.282Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Nachname*
          </div>
          <div class="Layout2345672">
            <input #thisinput_lastname [id]="prefix + 'lastname'" class="textInput" type="text" [value]="lastname" style="width: 100%"
              [(ngModel)]="lastname" (input)="thisinput_lastname.value" [style.backgroundcolor]="'#ffffff'">
          </div>
        </div>

        <div class="Zeile2345">
          <div class="Layout23456">
            <!-- Shape: svg -->
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="22" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust:exact" id="screenshot-43c4c9b0-5d53-809a-8003-cb831b18b86f"
              version="1.1" viewBox="7921 -2189.2 20 20" height="22">
              <g id="shape-43c4c9b0-5d53-809a-8003-cb831b18b86f" style="fill:#000000" width="24" height="24" rx="0"
                ry="0">
                <g id="shape-43c4c9b0-5d53-809a-8003-cb831b1b34ce">
                  <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb831b1b34ce">
                    <path rx="0" ry="0" style="fill:#666666"
                      d="M7930.583,-2188.783C7931.000,-2188.575,7931.833,-2187.200,7931.833,-2186.283C7931.833,-2185.367,7931.275,-2185.033,7930.583,-2185.033C7929.892,-2185.033,7929.333,-2185.158,7929.333,-2186.075C7929.333,-2186.992,7930.167,-2187.533,7930.583,-2188.783ZM7936.417,-2181.700C7938.500,-2181.700,7940.167,-2180.033,7940.167,-2177.950C7940.167,-2176.650,7939.508,-2175.508,7938.500,-2174.833L7938.500,-2170.033L7923.500,-2170.033L7923.500,-2174.833C7922.492,-2175.508,7921.833,-2176.650,7921.833,-2177.950C7921.833,-2180.033,7923.500,-2181.700,7925.583,-2181.700L7929.333,-2181.700L7929.333,-2184.200L7931.833,-2184.200L7931.833,-2181.700ZM7931.000,-2175.867C7932.151,-2175.867,7933.083,-2176.799,7933.083,-2177.950L7934.333,-2177.950C7934.333,-2176.799,7935.266,-2175.867,7936.417,-2175.867C7937.567,-2175.867,7938.500,-2176.799,7938.500,-2177.950C7938.500,-2179.101,7937.567,-2180.033,7936.417,-2180.033L7925.583,-2180.033C7924.433,-2180.033,7923.500,-2179.101,7923.500,-2177.950C7923.500,-2176.799,7924.433,-2175.867,7925.583,-2175.867C7926.734,-2175.867,7927.667,-2176.799,7927.667,-2177.950L7928.917,-2177.950C7928.917,-2176.799,7929.849,-2175.867,7931.000,-2175.867Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Geburtstag*
          </div>
          <div class="Layout234567">
              <mat-form-field appearance="outline" class="cust-mat-cal">
                <input matInput #thisinput_birthdate (input)="birthdate = thisinput_birthdate.value" [matDatepicker]="picker" [(ngModel)]="birthdate" [max]="maxDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
          </div>

        </div>
        <div class="Zeile234522">
          <div class="Layout2345622">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51be"
              version="1.1" viewBox="3666.602 -65.714 20 20" height="20">
              <g id="shape-34614192-5454-8094-8003-c77b7f5b51be" style="fill: #000000" width="32" height="32" rx="0"
                ry="0">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82b1">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82b1">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M3679.308,-52.289L3680.708,-53.689C3681.093,-54.069,3681.667,-54.187,3682.170,-53.989L3683.877,-53.307C3684.386,-53.100,3684.722,-52.607,3684.727,-52.057L3684.727,-48.932C3684.724,-48.563,3684.570,-48.210,3684.301,-47.956C3684.032,-47.703,3683.671,-47.570,3683.302,-47.589C3671.345,-48.332,3668.933,-58.457,3668.477,-62.332C3668.434,-62.717,3668.558,-63.102,3668.817,-63.390C3669.076,-63.678,3669.446,-63.841,3669.833,-63.839L3672.852,-63.839C3673.403,-63.837,3673.898,-63.500,3674.102,-62.989L3674.783,-61.282C3674.988,-60.780,3674.872,-60.204,3674.489,-59.820L3673.089,-58.420C3673.089,-58.420,3673.895,-52.964,3679.308,-52.289Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Telefonnummer*
          </div>
          <div class="Layout23456722">
            <ejs-dropdownlist
            id='ddlelement'
            [dataSource]='countryCode'
            [allowFiltering]='true'
            [(ngModel)]='agentCountryCode'
            class="outlined-dropdown me-2"
            [fields]="fields"
            (filtering)='onFiltering($event)'
            ></ejs-dropdownlist>
            <input #thisinput_phonenumber [id]="prefix + 'phonenumber'" class="textInput" type="text"
              [(ngModel)]="phonenumber"
              [style.backgroundcolor]="'#ffffff'" style="z-index: 1; width: 100%">
          </div>
        </div>

        <div class="Zeile2345">
          <div class="Layout23456">
            <!-- Shape: svg -->
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="22" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust:exact" id="screenshot-43c4c9b0-5d53-809a-8003-cb8128f3c743"
              version="1.1" viewBox="7920 -2100 20 20" height="20">
              <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f3c743" style="fill:#000000" width="24" height="24" rx="0"
                ry="0">
                <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f45516" style="fill:#000000" rx="0" ry="0">
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f4a0d0">
                    <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb8128f4a0d0">
                      <path fill="none" stroke-linecap="round" rx="0" ry="0" style="fill:none"
                        d="M7937.500,-2090.000C7937.500,-2093.213,7935.453,-2096.069,7932.411,-2097.102C7929.368,-2098.135,7926.006,-2097.115,7924.050,-2094.566C7922.094,-2092.016,7921.979,-2088.505,7923.764,-2085.833C7925.549,-2083.162,7928.838,-2081.924,7931.942,-2082.756">
                      </path>
                    </g>
                    <g id="strokes-43c4c9b0-5d53-809a-8003-cb8128f4a0d0" class="strokes">
                      <g class="stroke-shape">
                        <path stroke-linecap="round" rx="0" ry="0"
                          style="fill:none;fill-opacity:none;stroke-width:2;stroke:#666666;stroke-opacity:1;stroke-dasharray:"
                          d="M7937.500,-2090.000C7937.500,-2093.213,7935.453,-2096.069,7932.411,-2097.102C7929.368,-2098.135,7926.006,-2097.115,7924.050,-2094.566C7922.094,-2092.016,7921.979,-2088.505,7923.764,-2085.833C7925.549,-2083.162,7928.838,-2081.924,7931.942,-2082.756">
                        </path>
                      </g>
                    </g>
                  </g>
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f4a0d1">
                    <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb8128f4a0d1">
                      <ellipse fill="none" rx="3.333333333333485" ry="3.3333333333332575" style="fill:none" cx="7930"
                        cy="-2090" transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)">
                      </ellipse>
                    </g>
                    <g id="strokes-43c4c9b0-5d53-809a-8003-cb8128f4a0d1" class="strokes">
                      <g class="stroke-shape">
                        <ellipse rx="3.333333333333485" ry="3.3333333333332575"
                          style="fill:none;fill-opacity:none;stroke-width:2;stroke:#666666;stroke-opacity:1;stroke-dasharray:"
                          cx="7930" cy="-2090"
                          transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)">
                        </ellipse>
                      </g>
                    </g>
                  </g>
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f4ce61">
                    <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb8128f4ce61">
                      <path fill="none" stroke-linecap="round" rx="0" ry="0" style="fill:none"
                        d="M7933.333,-2092.500L7933.333,-2088.750C7933.333,-2087.599,7934.266,-2086.667,7935.417,-2086.667L7935.417,-2086.667C7936.567,-2086.667,7937.500,-2087.599,7937.500,-2088.750L7937.500,-2090.000">
                      </path>
                    </g>
                    <g id="strokes-43c4c9b0-5d53-809a-8003-cb8128f4ce61" class="strokes">
                      <g class="stroke-shape">
                        <path stroke-linecap="round" rx="0" ry="0"
                          style="fill:none;fill-opacity:none;stroke-width:2;stroke:#666666;stroke-opacity:1;stroke-dasharray:"
                          d="M7933.333,-2092.500L7933.333,-2088.750C7933.333,-2087.599,7934.266,-2086.667,7935.417,-2086.667L7935.417,-2086.667C7936.567,-2086.667,7937.500,-2087.599,7937.500,-2088.750L7937.500,-2090.000">
                        </path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            Mailadresse*
          </div>
          <div class="Layout234567">
            <input #thisinput_email [id]="prefix + 'email'" class="textInput" type="text" [value]="email" style="width: 100%"
              [(ngModel)]="email" (ngModelChange)="emailValueChanged($event)" [style.backgroundcolor]="'#ffffff'">
          </div>

        </div>
        <div class="Zeile2345222">
          <div class="Layout23456222">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51bf"
              version="1.1" viewBox="3663.602 -20.714 24 24" height="24">
              <g id="shape-34614192-5454-8094-8003-c77b7f5b51bf" style="fill: #000000" width="24" height="24" rx="0"
                ry="0">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82b3">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82b3">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M3675.602,-9.214C3674.221,-9.214,3673.102,-10.333,3673.102,-11.714C3673.102,-13.094,3674.221,-14.214,3675.602,-14.214C3676.982,-14.214,3678.102,-13.094,3678.102,-11.714C3678.102,-10.333,3676.982,-9.214,3675.602,-9.214ZM3675.602,-18.714C3671.736,-18.714,3668.602,-15.580,3668.602,-11.714C3668.602,-6.464,3675.602,1.286,3675.602,1.286C3675.602,1.286,3682.602,-6.464,3682.602,-11.714C3682.602,-15.580,3679.468,-18.714,3675.602,-18.714Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Standort*
          </div>
          <div class="Layout234567222">
            <input #thisinput_city [id]="prefix + 'city'" class="textInput me-2" type="text" [value]="city"
              [(ngModel)]="city" (input)="thisinput_city.value" [style.backgroundcolor]="'#ffffff'" placeholder="Ort">
            <input #thisinput_country [id]="prefix + 'country'" class="textInput" type="text" [value]="country"
              [(ngModel)]="country" (input)="thisinput_country.value" [style.backgroundcolor]="'#ffffff'" placeholder="Land">
          </div>
        </div>

        <div class="Zeile2345">
          <div class="Layout23456">
            <!-- Shape: svg -->
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust:exact" id="screenshot-43c4c9b0-5d53-809a-8003-cb80b3a55666"
              version="1.1" viewBox="7917 -2010.8 24 24" height="24">
              <g id="shape-43c4c9b0-5d53-809a-8003-cb80b3a55666" style="fill:#000000" width="24" height="24" rx="0"
                ry="0">
                <g id="shape-43c4c9b0-5d53-809a-8003-cb80b3a55668">
                  <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb80b3a55668">
                    <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.6"
                      d="M7924.000,-1999.800L7926.000,-1999.800L7926.000,-1997.800L7924.000,-1997.800ZM7924.000,-1995.800L7926.000,-1995.800L7926.000,-1993.800L7924.000,-1993.800ZM7928.000,-1999.800L7930.000,-1999.800L7930.000,-1997.800L7928.000,-1997.800ZM7928.000,-1995.800L7930.000,-1995.800L7930.000,-1993.800L7928.000,-1993.800ZM7932.000,-1999.800L7934.000,-1999.800L7934.000,-1997.800L7932.000,-1997.800ZM7932.000,-1995.800L7934.000,-1995.800L7934.000,-1993.800L7932.000,-1993.800Z">
                    </path>
                  </g>
                </g>
                <g id="shape-43c4c9b0-5d53-809a-8003-cb80b3a55669">
                  <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb80b3a55669">
                    <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.6"
                      d="M7922.000,-1988.800L7936.000,-1988.800C7937.103,-1988.800,7938.000,-1989.697,7938.000,-1990.800L7938.000,-2004.800C7938.000,-2005.903,7937.103,-2006.800,7936.000,-2006.800L7934.000,-2006.800L7934.000,-2008.800L7932.000,-2008.800L7932.000,-2006.800L7926.000,-2006.800L7926.000,-2008.800L7924.000,-2008.800L7924.000,-2006.800L7922.000,-2006.800C7920.897,-2006.800,7920.000,-2005.903,7920.000,-2004.800L7920.000,-1990.800C7920.000,-1989.697,7920.897,-1988.800,7922.000,-1988.800ZM7936.000,-2002.800L7936.001,-1990.800L7922.000,-1990.800L7922.000,-2002.800Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Kündigungsfrist
          </div>
          <div class="Layout234567">
            <ejs-dropdownlist [dataSource]='noticePeriodData' [(ngModel)]="noticePeriod" [fields]='field' class="outlined-dropdown notice-period"></ejs-dropdownlist>
          </div>

        </div>
        <div class="Zeile23452223">
          <div class="Layout234562223">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5ac6a2"
              version="1.1" viewBox="3664.602 26.286 24 24" height="24">
              <g id="shape-34614192-5454-8094-8003-c77b7f5ac6a2" style="fill: #000000" width="24" height="24" rx="0"
                ry="0">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b829d">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b829d">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M3671.602,37.286L3673.602,37.286L3673.602,39.286L3671.602,39.286ZM3671.602,41.286L3673.602,41.286L3673.602,43.286L3671.602,43.286ZM3675.602,37.286L3677.602,37.286L3677.602,39.286L3675.602,39.286ZM3675.602,41.286L3677.602,41.286L3677.602,43.286L3675.602,43.286ZM3679.602,37.286L3681.602,37.286L3681.602,39.286L3679.602,39.286ZM3679.602,41.286L3681.602,41.286L3681.602,43.286L3679.602,43.286Z">
                    </path>
                  </g>
                </g>
                <g id="shape-34614192-5454-8094-8003-c77b7f5b829e">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b829e">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M3669.602,48.286L3683.602,48.286C3684.705,48.286,3685.602,47.389,3685.602,46.286L3685.602,32.286C3685.602,31.183,3684.705,30.286,3683.602,30.286L3681.602,30.286L3681.602,28.286L3679.602,28.286L3679.602,30.286L3673.602,30.286L3673.602,28.286L3671.602,28.286L3671.602,30.286L3669.602,30.286C3668.499,30.286,3667.602,31.183,3667.602,32.286L3667.602,46.286C3667.602,47.389,3668.499,48.286,3669.602,48.286ZM3683.602,34.286L3683.603,46.286L3669.602,46.286L3669.602,34.286Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            ab wann verfügbar*
          </div>
          <div class="Layout2345672223">
              <mat-form-field appearance="outline" class="cust-mat-cal">
                <input matInput #thisinput_availableForm (input)="availableFrom = thisinput_availableForm.value" [matDatepicker]="picker1" [(ngModel)]="availableFrom">
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
          </div>
        </div>
        <div class="Zeile234523">
          <div class="Layout2345623" style="align-items: flex-start;">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5ac6a3"
              version="1.1" viewBox="3664.602 78.286 20 16" height="16">
              <g id="shape-34614192-5454-8094-8003-c77b7f5ac6a3" style="fill: #000000" width="16" height="16" rx="0"
                ry="0">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82a0">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82a0">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M3666.789,81.286C3666.271,81.286,3665.852,81.622,3665.852,82.036C3665.852,82.451,3666.271,82.786,3666.789,82.786L3682.414,82.786C3682.932,82.786,3683.352,82.451,3683.352,82.036C3683.352,81.622,3682.932,81.286,3682.414,81.286ZM3666.789,84.286C3666.271,84.286,3665.852,84.622,3665.852,85.036C3665.852,85.451,3666.271,85.786,3666.789,85.786L3682.414,85.786C3682.932,85.786,3683.352,85.451,3683.352,85.036C3683.352,84.622,3682.932,84.286,3682.414,84.286ZM3665.852,88.036C3665.852,87.622,3666.271,87.286,3666.789,87.286L3682.414,87.286C3682.932,87.286,3683.352,87.622,3683.352,88.036C3683.352,88.451,3682.932,88.786,3682.414,88.786L3666.789,88.786C3666.271,88.786,3665.852,88.451,3665.852,88.036ZM3666.789,90.286C3666.271,90.286,3665.852,90.622,3665.852,91.036C3665.852,91.451,3666.271,91.786,3666.789,91.786L3677.414,91.786C3677.932,91.786,3678.352,91.451,3678.352,91.036C3678.352,90.622,3677.932,90.286,3677.414,90.286Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Erfahrungen*
          </div>
          <div class="Layout23456723">
            <textarea #thisinput_experiences [id]="prefix + 'experiences'" class="textarea" [value]="experiences"
              [(ngModel)]="experiences" (input)="thisinput_experiences.value"
              [style.backgroundcolor]="'#ffffff'" style="z-index: 1;"></textarea>
          </div>
        </div>
        <div class="Zeile23452222">
          <div class="Layout234562222">
          </div>
        </div>
      </div>
      <div class="rightSide">

        <div class="Zeile23452345">
          <div class="Layout234562345">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51ae"
              version="1.1" viewBox="4167.602 -83.714 24 24" height="24">
              <g id="shape-34614192-5454-8094-8003-c77b7f5b51ae" style="fill: #000000" width="24" height="24" rx="0"
                ry="0">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82aa">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82aa">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M4174.602,-72.714L4176.602,-72.714L4176.602,-70.714L4174.602,-70.714ZM4174.602,-68.714L4176.602,-68.714L4176.602,-66.714L4174.602,-66.714ZM4178.602,-72.714L4180.602,-72.714L4180.602,-70.714L4178.602,-70.714ZM4178.602,-68.714L4180.602,-68.714L4180.602,-66.714L4178.602,-66.714ZM4182.602,-72.714L4184.602,-72.714L4184.602,-70.714L4182.602,-70.714ZM4182.602,-68.714L4184.602,-68.714L4184.602,-66.714L4182.602,-66.714Z">
                    </path>
                  </g>
                </g>
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82ab">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82ab">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M4172.602,-61.714L4186.602,-61.714C4187.705,-61.714,4188.602,-62.611,4188.602,-63.714L4188.602,-77.714C4188.602,-78.817,4187.705,-79.714,4186.602,-79.714L4184.602,-79.714L4184.602,-81.714L4182.602,-81.714L4182.602,-79.714L4176.602,-79.714L4176.602,-81.714L4174.602,-81.714L4174.602,-79.714L4172.602,-79.714C4171.499,-79.714,4170.602,-78.817,4170.602,-77.714L4170.602,-63.714C4170.602,-62.611,4171.499,-61.714,4172.602,-61.714ZM4186.602,-75.714L4186.603,-63.714L4172.602,-63.714L4172.602,-75.714Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Status
          </div>
          <div class="Layout2345672345">
            <select #thisinput_status [id]="prefix + 'status'" class="textInput" [(ngModel)]="status"
              (change)="thisinput_status.value" [style.backgroundcolor]="'#ffffff'">
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </select>
          </div>
        </div>
        <div class="Zeile23452345">
          <div class="Layout234562345">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51ae"
              version="1.1" viewBox="4167.602 -83.714 24 24" height="24">
              <g id="shape-34614192-5454-8094-8003-c77b7f5b51ae" style="fill: #000000" width="24" height="24" rx="0"
                ry="0">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82aa">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82aa">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M4174.602,-72.714L4176.602,-72.714L4176.602,-70.714L4174.602,-70.714ZM4174.602,-68.714L4176.602,-68.714L4176.602,-66.714L4174.602,-66.714ZM4178.602,-72.714L4180.602,-72.714L4180.602,-70.714L4178.602,-70.714ZM4178.602,-68.714L4180.602,-68.714L4180.602,-66.714L4178.602,-66.714ZM4182.602,-72.714L4184.602,-72.714L4184.602,-70.714L4182.602,-70.714ZM4182.602,-68.714L4184.602,-68.714L4184.602,-66.714L4182.602,-66.714Z">
                    </path>
                  </g>
                </g>
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82ab">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82ab">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M4172.602,-61.714L4186.602,-61.714C4187.705,-61.714,4188.602,-62.611,4188.602,-63.714L4188.602,-77.714C4188.602,-78.817,4187.705,-79.714,4186.602,-79.714L4184.602,-79.714L4184.602,-81.714L4182.602,-81.714L4182.602,-79.714L4176.602,-79.714L4176.602,-81.714L4174.602,-81.714L4174.602,-79.714L4172.602,-79.714C4171.499,-79.714,4170.602,-78.817,4170.602,-77.714L4170.602,-63.714C4170.602,-62.611,4171.499,-61.714,4172.602,-61.714ZM4186.602,-75.714L4186.603,-63.714L4172.602,-63.714L4172.602,-75.714Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Interview Termin
          </div>
          <div class="Layout2345672345">{{ interviewDate || '15.02.2023 11:00 Uhr'}}</div>
        </div>
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="452" xmlns="http://www.w3.org/2000/svg"
          style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51bb" version="1.1"
          viewBox="4167.602 -198.714 452 25" height="25">
          <style data-loading="true"></style>
          <g id="shape-34614192-5454-8094-8003-c77b7f5b51bb">
            <defs>
              <pattern patternUnits="userSpaceOnUse" x="4167.601700792034" y="-198.71354914285575" height="25"
                width="452" data-loading="false" id="fill-0-rumext-id-1037">
                <g>
                  <rect style="fill: #333333; fill-opacity: 1" width="452" height="25"></rect>
                </g>
              </pattern>
            </defs>
            <g transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)" class="text-container"
              x="4167.601700792034" y="-198.71354914285575" width="452" height="25" rx="0" ry="0">
              <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b51bb">
                <text x="4167.601700792034" y="-175.3802156505767" dominant-baseline="ideographic"
                  textLength="138.42015075683594" lengthAdjust="spacingAndGlyphs" style="
                  text-transform: none;
                  font-family: Roboto;
                  letter-spacing: normal;
                  font-style: normal;
                  font-weight: 700;
                  white-space: pre;
                  font-size: 20px;
                  text-decoration: none solid rgb(51, 51, 51);
                  direction: ltr;
                  fill: #333333;
                  fill-opacity: 1;
                ">
                  Interne Notizen
                </text>
              </g>
            </g>
          </g>
        </svg>
        <div class="Zeile2345234">
          <div class="Layout23456234" style="align-items: flex-start;">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5ac6a3"
              version="1.1" viewBox="3664.602 78.286 20 16" height="16">
              <g id="shape-34614192-5454-8094-8003-c77b7f5ac6a3" style="fill: #000000" width="16" height="16" rx="0"
                ry="0">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b82a0">
                  <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82a0">
                    <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                      d="M3666.789,81.286C3666.271,81.286,3665.852,81.622,3665.852,82.036C3665.852,82.451,3666.271,82.786,3666.789,82.786L3682.414,82.786C3682.932,82.786,3683.352,82.451,3683.352,82.036C3683.352,81.622,3682.932,81.286,3682.414,81.286ZM3666.789,84.286C3666.271,84.286,3665.852,84.622,3665.852,85.036C3665.852,85.451,3666.271,85.786,3666.789,85.786L3682.414,85.786C3682.932,85.786,3683.352,85.451,3683.352,85.036C3683.352,84.622,3682.932,84.286,3682.414,84.286ZM3665.852,88.036C3665.852,87.622,3666.271,87.286,3666.789,87.286L3682.414,87.286C3682.932,87.286,3683.352,87.622,3683.352,88.036C3683.352,88.451,3682.932,88.786,3682.414,88.786L3666.789,88.786C3666.271,88.786,3665.852,88.451,3665.852,88.036ZM3666.789,90.286C3666.271,90.286,3665.852,90.622,3665.852,91.036C3665.852,91.451,3666.271,91.786,3666.789,91.786L3677.414,91.786C3677.932,91.786,3678.352,91.451,3678.352,91.036C3678.352,90.622,3677.932,90.286,3677.414,90.286Z">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            Notizen zum Agenten
          </div>
          <div class="Layout234567234">
            <textarea #thisinput_internalNotes [id]="prefix + 'internalNotes'" class="textarea" type="time"
              [value]="internalNotes" [(ngModel)]="internalNotes" (input)="thisinput_internalNotes.value"
              [style.backgroundcolor]="'#ffffff'"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lastRow">
    <button class="default-button" (click)="cancel()">Abbrechen</button>
    <button class="save-button" (click)="createAgent()" [disabled]="!validateForm()">Speichern</button>
  </div>
</div>
