import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RecordingService } from '../utils/services/recording.service';
import { Agent } from '../utils/models/agent.model';
import { AgentsService } from '../utils/services/agents.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../utils/services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { Message } from '../utils/models/message.model';
import * as moment from 'moment';
import { KeycloakService } from 'keycloak-angular';
import { MessageService } from '../utils/services/message.service';
import { catchError } from 'rxjs';
import { ConfigService } from '../config.service';
@Component({
  selector: 'app-voice-player',
  templateUrl: './voice-player.component.html',
  styleUrl: './voice-player.component.scss',
})
export class VoicePlayerComponent implements OnInit {
  @Input() recordings: any;
  @Input() isDeleteActive: any;
  @Input() isAdminLoggedIn: any;
  @Input() currentRole: any;
  @Input() currentUserEmail: any;
  @Input() isPublic: any;

  blobUrls: any = [];
  videoBlobUrls: any = [];
  blobUrl: any;
  audioPlaying: boolean[] = [];
  videoPlaying: boolean[] = [];
  currentVideoBlobUrl: string = '';
  agent!: any;
  currentPath = '';
  publicId: string = '';
  isRecording = false;
  acceptedMediaType: string = '';
  showDeleteConfirmationDialog: boolean = false;
  deleteIndex: number = -1;
  deletionType: string = '';
  isSpinnerActive: boolean = false;
  currentVideoStremUrl: any = '';
  currentAudioStremUrl: any = '';

  constructor(
    private recordingService: RecordingService,
    private sharedService: SharedService,
    private agentsService: AgentsService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly keycloak: KeycloakService,
    private messageService: MessageService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    // this.getAgentData();
    this.route.params.subscribe((params) => {
      const id = params['id'];
      this.publicId = params['publicId'];
    });
    this.recordingService.isRecordingSubject.subscribe((isRecording) => {
      this.isRecording = isRecording;
    });
    this.sharedService.agent$.subscribe((agent: any) => {
      this.agent = agent;
      if (agent?.voiceFiles?.length > 0) {
        this.generateAgentBlobUrlsArray(agent);
      } else {
        // console.error('No voice files found.');
      }

      if (agent?.videoFiles?.length > 0) {
        this.generateAgentVideoBlobUrlsArray(agent);
      } else {
        // console.error('No video files found.');
      }
      if (this.currentRole == 'Client') {
        this.getAcceptedMedia();
      }
    });
    this.sharedService.agentVoices$.subscribe((agent: any) => {
      this.agent = agent;
      this.generateAgentBlobUrlsArray(agent);
      this.generateAgentVideoBlobUrlsArray(agent);
    });

    if (this.currentRole == 'Client') {
      this.getAcceptedMedia();
    }
  }

  generateAgentBlobUrlsArray(agent: any) {
    this.blobUrls = agent.voiceFiles?.map((voiceFile: any) => {
      if (voiceFile.voiceBlob && voiceFile.voiceBlob.data) {
        const base64String = this.recordingService.arrayToBase64(
          voiceFile.voiceBlob.data
        );
        const mimeType = 'audio/mpeg';
        const blobUrl = this.recordingService.blobToUrl(base64String, mimeType);
        voiceFile.blobUrl = blobUrl;
        return voiceFile;
      }
      return null;
    });
  }

  generateAgentVideoBlobUrlsArray(agent: any) {
    this.videoBlobUrls = agent.videoFiles?.map((videoFile: any) => {
      if (videoFile.videoBlob && videoFile.videoBlob.data) {
        const base64String = this.recordingService.arrayToBase64(
          videoFile.videoBlob.data
        );
        const mimeType = 'video/webm';
        const blobUrl = this.recordingService.blobToUrl(base64String, mimeType);
        videoFile.blobUrl = blobUrl;
        return videoFile;
      }
      return null;
    });
  }

  toggleAudio(index: number) {
    const audioElement = document.getElementById(
      `audioTag-${index}`
    ) as HTMLAudioElement;
    const playButton = document.getElementById(`play-button-${index}`);

    if (audioElement && playButton) {
      if (audioElement.paused) {
        if (this.blobUrls.length > 1) {
          for (let i = 0; i < this.blobUrls.length; i++) {
            if (i != index) {
              const toPauseElement = document.getElementById(
                `audioTag-${i}`
              ) as HTMLAudioElement;
              toPauseElement.pause();
              audioElement.play();
              this.audioPlaying[i] = false;
              this.audioPlaying[index] = true;
            }
          }
        } else {
          audioElement.play();
          this.audioPlaying[index] = true;
        }

        audioElement.addEventListener('ended', () => {
          this.audioPlaying[index] = false;
        });
        if (!this.blobUrls[index].isPlayed) {
          this.changeIsplayedStatu(this.blobUrls[index], true);
          this.blobUrls[index].isPlayed = true;
        }
      } else {
        audioElement.pause();
        this.audioPlaying[index] = false;
      }
    }
  }

  playVideo(videoFile: any) {
    // console.log('this.videoBlobUrls[index].id::::', this.videoBlobUrls[index].id);
    // this.currentVideoBlobUrl = this.videoBlobUrls[index].blobUrl;
    this.currentVideoBlobUrl = this.agentsService.getVideoStreamUrl(videoFile.id);
    const videoElement = document.getElementById(
      'videoTag'
    ) as HTMLVideoElement;
    if (videoElement) {
      videoElement.play();
    }
    if (!videoFile.isPlayed) {
      this.changeIsplayedStatu(videoFile, false);
      videoFile.isPlayed = true;
    }
  }

  async getBlobUrl(voiceBlobData: any) {
    const base64String = this.recordingService.arrayToBase64(voiceBlobData);
    const mimeType = 'audio/mpeg';
    const blobUrl = this.recordingService.blobToUrl(base64String, mimeType);
    return blobUrl;
  }

  deleteAudio(index: number) {
    const audioFileId = this.agent.voiceFiles[index].id;
    this.recordingService
      .deleteRecording(this.publicId, audioFileId, 'Audio')
      .subscribe(
        (res: any) => {
          const text = `${this.agent.firstname} ${this.agent.lastname} agent hat Audiodatei mit Namen ${this.agent.voiceFiles[index].filename} gelöscht.`;
          this.snackBar.open('Audio file erfolgreich gelöscht!', '', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
          this.agent.voiceFiles.splice(index, 1);
          this.blobUrls.splice(index, 1);
          this.deleteIndex = -1;
          this.deletionType = '';
          this.showDeleteConfirmationDialog = false;
          // this.sharedService.agentVoicesSubject.next(this.agent);
          // this.agent = res;
          this.sharedService.logAgentMoment(this.agent, this.agent.emailAddress, 'Agent', text);
        },
        (error) => {
          console.log('Audio errror', error);
          this.snackBar.open(
            'Audio file Löschung fehlgeschlagen, bitte versuchen Sie es später noch einmal!',
            '',
            {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
            }
          );
        }
      );
  }

  deleteVideo(index: number) {
    const videoFileId = this.agent.videoFiles[index].id;
    this.recordingService
      .deleteRecording(this.publicId, videoFileId, 'Video')
      .subscribe(
        (res: any) => {
          const text = `${this.agent.firstname} ${this.agent.lastname} agent hat Videodatei mit Namen ${this.agent.videoFiles[index].filename} gelöscht.`;
          this.snackBar.open('Video file erfolgreich gelöscht!', '', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
          this.agent.videoFiles.splice(index, 1);
          this.videoBlobUrls.splice(index, 1);
          this.deleteIndex = -1;
          this.deletionType = '';
          this.showDeleteConfirmationDialog = false;
          // this.sharedService.agentVoicesSubject.next(this.agent);
          // this.agent = res;
          this.sharedService.logAgentMoment(this.agent, this.agent.emailAddress, 'Agent', text);
        },
        (error) => {
          this.snackBar.open(
            'Video file Löschung fehlgeschlagen, bitte versuchen Sie es später noch einmal!',
            '',
            {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
            }
          );
        }
      );
  }

  acceptAudio(index: number) {
    this.isSpinnerActive = true;
    let audio = this.agent.voiceFiles[index];
    this.recordingService
      .updateAudioStatus(audio.id, 'accepted')
      .subscribe((response: any) => {
        if (response) {
          this.agent.voiceFiles[index].status = 'accepted';
          this.sharedService.onFileAccepted.next({isAudioFile:true,agentId:this.agent.id,status:this.agent.status,fileId:audio.id})
          for (let i = 0; i < this.agent.voiceFiles.length; i++) {
            if (i !== index) {
              this.agent.voiceFiles[i].status = 'rejected';
              this.recordingService
                .updateAudioStatus(this.agent.voiceFiles[i].id, 'rejected')
                .subscribe();
            }
          }
          this.isSpinnerActive = false;
        }
      });
  }

  acceptVideo(video: any) {
    this.isSpinnerActive = true;
    this.recordingService.updateVideoStatus(video.id, 'accepted').subscribe((response: any) => {
        if (response) {
            video.status = 'accepted';

            this.sharedService.onFileAccepted.next({
                isAudioFile: false,
                agentId: this.agent.id,
                status: this.agent.status,
                fileId: video.id
            });

            for (let i = 0; i < this.agent.videoFiles.length; i++) {
                if (this.agent.videoFiles[i].id !== video.id) {
                    this.agent.videoFiles[i].status = 'rejected';
                    this.recordingService.updateVideoStatus(this.agent.videoFiles[i].id, 'rejected').subscribe();
                }
            }
            this.isSpinnerActive = false;
        }
    });
}

  rejectAudio(index: number) {
    this.isSpinnerActive = true;
    let audio = this.agent.voiceFiles[index];
    this.recordingService
      .updateAudioStatus(audio.id, 'rejected')
      .subscribe((response) => {
        if (response) {
          this.sharedService.onFileRejected.next({isAudioFile:true,agentId:this.agent.id,status:this.agent.status,fileId:audio.id})
          this.agent.voiceFiles[index].status = 'rejected';
          this.isSpinnerActive = false;
        }
      });
  }

  rejectVideo(video: any) {
    this.isSpinnerActive = true;
    this.recordingService.updateVideoStatus(video.id, 'rejected').subscribe((response) => {
        if (response) {
            this.sharedService.onFileRejected.next({
                isAudioFile: false,
                agentId: this.agent.id,
                status: this.agent.status,
                fileId: video.id
            });
            video.status = 'rejected';
            this.isSpinnerActive = false;
        }
    });
}

  acceptMedia(index: any, type: string) {
    if (type === 'Audio') {
      const audio = this.agent.voiceFiles[index];
      this.checkExistingAccepted();
      this.agent.voiceFiles[index].status = 'accepted';
      this.changeDetectorRef.detectChanges();
      this.recordingService
        .updateAudioStatus(audio.id, 'accepted')
        .subscribe((response: any) => {});
    } else if (type === 'Video') {
      const video = this.agent.videoFiles[index];
      this.checkExistingAccepted();
      this.agent.videoFiles[index].status = 'accepted';
      this.changeDetectorRef.detectChanges();
      this.recordingService
        .updateVideoStatus(video.id, 'accepted')
        .subscribe((response: any) => {});
    }
  }

  checkExistingAccepted() {
    for (let i = 0; i < this.agent.voiceFiles.length; i++) {
      if (this.agent.voiceFiles[i].status == 'accepted') {
        this.agent.voiceFiles[i].status = 'rejected';
        this.recordingService
          .updateAudioStatus(this.agent.voiceFiles[i].id, 'rejected')
          .subscribe();
      }
    }
    for (let i = 0; i < this.agent.videoFiles.length; i++) {
      if (this.agent.videoFiles[i].status == 'accepted') {
        this.agent.videoFiles[i].status = 'rejected';
        this.recordingService
          .updateVideoStatus(this.agent.videoFiles[i].id, 'rejected')
          .subscribe();
      }
    }
  }

  getAcceptedMedia() {
    if (this.blobUrls) {
      const acceptedVoice = this.blobUrls.filter(
        (voice: any) => voice.status == 'accepted'
      );
      if (acceptedVoice.length > 0) {
        this.acceptedMediaType = 'Audio';
        this.blobUrls = acceptedVoice;
      } else {
        this.blobUrls = [];
      }
    }

    if (this.videoBlobUrls) {
      const acceptedVideo = this.videoBlobUrls.filter(
        (video: any) => video.status === 'accepted'
      );
      if (acceptedVideo.length > 0) {
        this.acceptedMediaType = 'Video';
        this.videoBlobUrls = acceptedVideo;
      } else {
        this.videoBlobUrls = [];
      }
    }
  }

  sendVoiceFileRequest() {
    this.recordingService.requestVoiceFile(this.agent.id).subscribe(() => {
      this.snackBar.open('Audio-Anfrage gesendet', '', {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
    });
  }

  public copyLinkToClipboard(existingAgent: Agent) {
    const link = `${this.configService.linkUrl}/public/agentView/${existingAgent.public_id}`;

    navigator.clipboard.writeText(link).then(
      () => {
        this.snackBar.open('Link kopiert!', '', {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
        });
      },
      (err) => {
        console.error('Error copying link to clipboard', err);
      }
    );
  }

  changeIsplayedStatu(file: any, isVoiceFile: boolean) {
    this.recordingService
      .changeMediaFilePlayedStatus(file.id, { isVoiceFile })
      .subscribe((res: any) => {
        if (isVoiceFile) {
          this.agent.voiceFiles.forEach((element: any) => {
            if (element.id == res.id) {
              element.isPlayed = true;
            }
          });
        } else {
          this.agent.videoFiles.forEach((element: any) => {
            if (element.id == res.id) {
              element.isPlayed = true;
            }
          });
        }
        this.sharedService.agentVoiceFileSubject.next({
          ...this.agent,
          isVoiceFile,
          fileStatusChangeId: res.id,
        });
      });
  }

  showDeleteConfirmation(index: number, type: string) {
    this.showDeleteConfirmationDialog = true;
    this.deleteIndex = index;
    this.deletionType = type;
  }

  deleteFile() {
    if (this.deletionType == 'audio' && this.deleteIndex != -1) {
      this.deleteAudio(this.deleteIndex);
    } else if (this.deletionType == 'video' && this.deleteIndex != -1) {
      this.deleteVideo(this.deleteIndex);
    }
  }
}
