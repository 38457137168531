import { Component, OnInit } from '@angular/core';

import { PartnersService } from '../utils/services/partners.service';
import { Partner } from '../utils/models/partner.model';
import { DialogService } from '../dialogservice.service';

@Component({
  selector: 'partner-management',
  templateUrl: './partner-management.component.html',
  styleUrl: './partner-management.component.scss'
})
export class PartnerManagementComponent implements OnInit {
  public isSpinnerActive: boolean = false;

  public deleteConfirmationPopup: boolean = false;
  public partnerForDelete?: { partner: Partner, user: any } = undefined;
  public selectedPartner?: Partner = undefined;

  public constructor(
    public readonly partnersService: PartnersService,
    public readonly dialogService: DialogService
  ) {
  }

  public ngOnInit(): void {
    this.isSpinnerActive = true;

    this.partnersService.fetchAll(() => {
      this.isSpinnerActive = false;
    });
  }

  public addPartnerAssignment(): void {
    this.dialogService.showNewPartnerDialog = true;
    this.partnersService.isForUpdate = false;
    this.selectedPartner = undefined;
  }

  public onClickDelete(partnerAssignment: { partner: Partner, user: any }): void {
    this.partnerForDelete = partnerAssignment;
    this.deleteConfirmationPopup = true;
  }

  public onClickUser(partnerAssignment: { partner: Partner, user: any }): void {
    this.selectedPartner = partnerAssignment.partner;
    this.partnersService.isForUpdate = true;
    this.dialogService.showNewPartnerDialog = true;
  }

  public onClickJa(): void {
    this.partnersService.delete(this.partnerForDelete!.partner.id).subscribe(() => {
      this.partnersService.partnerAssignments = this.partnersService.partnerAssignments.filter(
        (assignment) => assignment.partner.id !== this.partnerForDelete!.partner.id
      );
      this.deleteConfirmationPopup = false;
      this.partnerForDelete = undefined;
    });
  }
}
