import { Component, OnInit } from '@angular/core';

import { CustomersService } from '../utils/services/customers.service';
import { Customer } from '../utils/models/customer.model';
import { DialogService } from '../dialogservice.service';

@Component({
  selector: 'customer-management',
  templateUrl: './customer-management.component.html',
  styleUrl: './customer-management.component.scss'
})
export class CustomerManagementComponent implements OnInit {
  public isSpinnerActive: boolean = false;

  public deleteConfirmationPopup: boolean = false;
  public customerForDelete?: { customer: Customer, user: any } = undefined;
  public selectedCustomer?: Customer = undefined;

  public constructor(
    public readonly customersService: CustomersService,
    public readonly dialogService: DialogService
  ) {
  }

  public ngOnInit(): void {
    this.isSpinnerActive = true;

    this.customersService.fetchAll(() => {
      this.isSpinnerActive = false;
    });
  }

  public addCustomerAssignment(): void {
    this.dialogService.showNewCustomerDialog = true;
    this.customersService.isForUpdate = false;
    this.selectedCustomer = undefined;
  }

  public onClickDelete(customerAssignment: { customer: Customer, user: any }): void {
    this.customerForDelete = customerAssignment;
    this.deleteConfirmationPopup = true;
  }

  public onClickUser(customerAssignment: { customer: Customer, user: any }): void {
    this.selectedCustomer = customerAssignment.customer;
    this.customersService.isForUpdate = true;
    this.dialogService.showNewCustomerDialog = true;
  }

  public onClickJa(): void {
    this.customersService.delete(this.customerForDelete!.customer.id).subscribe(() => {
      this.customersService.customerAssignments = this.customersService.customerAssignments.filter(
        (assignment) => assignment.customer.id !== this.customerForDelete!.customer.id
      );
      this.deleteConfirmationPopup = false;
      this.customerForDelete = undefined;
    });
  }
}
