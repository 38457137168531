<div class="Projektübersicht" *ngIf="isProjectDataFetched">
  <div class="headingLayout">
    <div class="cust-breadcrumb">
      <div class="richText2">
        <span style="font-size: 18pt;">
          <strong>
            <span style="color: rgb(36, 184, 155); cursor: pointer;" [routerLink]="['/projectoverview']"
              routerLinkActive="active-link">Projekte</span>
            <span style="color: rgb(36, 184, 155);"> / </span>
          </strong>
        </span>
        <span style="font-size: 18pt; color: rgb(36, 184, 155); font-weight: bold">{{ project?.name }}</span>
      </div>
      <div class="project-desc" (click)="onClickProjectDesc()">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
          <path fill="currentColor"
            d="M4.75 7a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5zM5 4.75A.75.75 0 0 1 5.75 4h5.5a.75.75 0 0 1 0 1.5h-5.5A.75.75 0 0 1 5 4.75M6.75 10a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5z" />
          <path fill="currentColor"
            d="M0 1.75C0 .784.784 0 1.75 0h12.5C15.216 0 16 .784 16 1.75v12.5A1.75 1.75 0 0 1 14.25 16H1.75A1.75 1.75 0 0 1 0 14.25Zm1.75-.25a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25V1.75a.25.25 0 0 0-.25-.25Z" />
        </svg>
        <span>Projektbeschreibung</span>
      </div>
    </div>
    <div *ngIf="isAdminLoggedIn">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn flex-shrink-0 btn-left" (click)="adminSelected()"
          [class.active]="currentRole === 'Admin'">
          Admin Ansicht
        </button>
        <button type="button" class="btn flex-shrink-0 btn-right" (click)="clientSelected()"
          [class.active]="currentRole === 'Client'">
          Kunden Ansicht
        </button>
      </div>
    </div>
  </div>
  <div class="projectArea">
    <div class="d-flex flex-column gap-3 w-100 h-100 overflow-x-auto">
      <div class="d-flex flex-row gap-3 w-100" style="height: 100%;">
        <div *ngFor="let status of userStatus" style="max-width: 348px; width: 100%; height: auto; flex-shrink: 0; margin-top: 8%; position: relative;
          transition: all 0.3s ease-in-out" class="cardListColumn"
          [ngStyle]="{ 'width': status.collapsed ? 'auto' : '100%','margin-top': currentRole == 'Partner' ? '1%' : '8%'}">
          <drag-and-drop-card-list [id]="status.name" [headerText]="status" [data]="status?.agents"
            [terminAgentsCount]="terminData?.agents?.length" [scheduledCount]="scheduledCount"
            (dropEntry)="onDropFromActive($event)">
            <ng-template #cardTemplate let-entry>
              <div *ngIf="entry.id != 0" (click)="navigateToSingleView(entry.id, status.name)" class="cardDetail"
                [ngClass]="getBorderColor(entry)">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-column">
                    <span style="color: #222222; font-size: 14px; font-weight: 500">{{ entry.name }}</span>
                    <span style="
                        color: #6c6c6c;
                        font-size: 12px;
                        font-family: 'Source Sans Pro', sans-serif;
                      ">{{entry.city}} {{entry.country}}</span>
                  </div>
                  <div style="display: flex; flex-direction: column; gap: 8px; justify-content: center; align-items: flex-end;">
                    <div class="profile-img mb-1">
                      <img [src]="entry.profileImage" alt="Agent profile image" #profileImage (load)="adjustImage(profileImage)" *ngIf="entry.profileImage"/>
                      <img src="../../assets/Icon avatar 1.svg" alt="Agent profile image" class="h-100 w-100" *ngIf="!entry.profileImage"/>
                    </div>
                    <div style="display: flex; flex-direction: row; gap: 8px;">
                      <div class="small-button" (click)="openMediaModal($event, entry.id)" *ngIf="isPlayIconVisible(entry)" [ngStyle]="{'background-color': getPlayIconColor(entry)}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="#FFFFFF"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM10.6219 8.41459C10.5562 8.37078 10.479 8.34741 10.4 8.34741C10.1791 8.34741 10 8.52649 10 8.74741V15.2526C10 15.3316 10.0234 15.4088 10.0672 15.4745C10.1897 15.6583 10.4381 15.708 10.6219 15.5854L15.5008 12.3328C15.5447 12.3035 15.5824 12.2658 15.6117 12.2219C15.7343 12.0381 15.6846 11.7897 15.5008 11.6672L10.6219 8.41459Z"></path></svg>
                      </div>
                      <div class="small-button" *ngIf="isMessageIconVisible(entry)">
                        <div style="color: #FFFFFF;">{{ unreadMessagesCount.has(entry.id!) ? unreadMessagesCount.get(entry.id!) : 0 }}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 48 48"><path fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M44 24c0 11.046-8.954 20-20 20H4V24C4 12.954 12.954 4 24 4s20 8.954 20 20m-30-6h18m-18 8h18m-18 8h10"/></svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="entry.id == 0 && currentRole !== 'Client'" (click)="showCreateAgentDialog()"
                class="createAgent" style="cursor: pointer;">
                <div class="d-flex align-items-center justify-content-between" style="color: rgb(36, 184, 155)">
                  <span style="font-size: 14px">Agent anlegen</span>
                  <span class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                      fill="currentColor">
                      <path
                        d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z">
                      </path>
                    </svg>
                  </span>
                </div>
              </div>
            </ng-template>
          </drag-and-drop-card-list>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Backdrop -->
<div *ngIf="dialogService.showAgentView" class="backdrop"></div>
<div class="app-agentview" [ngClass]="{ 'dataModal': dialogService.showAgentView }">
  <app-agentview
    [data]="data"
    [projectViewsCurrentRole]="currentRole"
    *ngIf="dialogService.showAgentView"
    (onstatusChange)="onAgentStatusChange($event)"
    (onMoveAgent)="onMoveAgent()"
    [ngClass]="{ 'h-calc': !dialogService.showAgentView }"
    [statuses]="statuses"
    (onselectInterviewDate)="onselectInterviewDate($event)">
  </app-agentview>
</div>

<div *ngIf="dialogService.showTimeSlotDialog" class="backdrop"></div>
<timeSlotsDialog *ngIf="dialogService.showTimeSlotDialog" [agentId]="agentId" [projectId]="id" [currentUserRole]="currentRole"
  (scheduledDurationEvent)="scheduledDurationChanged($event)" (updatescheduledCountEvent)="updatescheduledCount()"></timeSlotsDialog>

<div *ngIf="dialogService.showCreateAgentDialog" class="backdrop"></div>
<createAgentDialog *ngIf="dialogService.showCreateAgentDialog" [project]="project" [statuses]="statuses"
  [partnerId]="partnerId"></createAgentDialog>

  <div *ngIf="isMediaPlay" class="backdrop"></div>
<div class="modalView" *ngIf="isMediaPlay">
  <div class="close-btn ms-auto mb-3" style="margin-right: -8px;" (click)="isMediaPlay = false;selectedAudioUrl='';selectedVideoUrl=''">
    <span class="d-block" style="color: rgb(102, 102, 102);">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
        <path
          d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z">
        </path>
      </svg>
    </span>
  </div>
  <div class="w-100 overflow-y-auto">
    <div class="detailArea">
      <div class="video-wrapper mx-auto d-block mb-3" *ngIf="selectedVideoUrl">
        <div class="player-heading">
          Videodatei
        </div>
        <video width="500" controls>
          <source [src]="selectedVideoUrl" type="video/webm">
        </video>
      </div>
      <div class="video-wrapper mx-auto d-block" *ngIf="selectedAudioUrl">
        <div class="player-heading">
          Audiodatei
        </div>
        <audio controls class="w-100">
          <source [src]="selectedAudioUrl" type="audio/mpeg">
        </audio>
      </div>
    </div>
  </div>
</div>

<!-- Backdrop -->
<div *ngIf="dialogService.showNewProjectDialog" class="backdrop"></div>

<newProject *ngIf="dialogService.showNewProjectDialog" [selectedProject]="project" [isDescriptionModal]="true"></newProject>
@if(isSpinnerActive) {
  <app-spinner></app-spinner>
}

<!-- Skeleton start -->
<div class="top-container" *ngIf="!isProjectDataFetched">
  <div class="top-container-box">
    <ejs-skeleton shape="Rectangle" width="100%" height="90%"></ejs-skeleton>
  </div>

  <div class="top-container-box">
    <ejs-skeleton shape="Rectangle" width="100%" height="90%"></ejs-skeleton>
  </div>
</div>

<div class="main-container">
<div class="top-boxes">
  <!-- Top Box for Column 1 -->
  <div class="top-box">
    <ejs-skeleton shape="Rectangle" width="80%" height="50%"></ejs-skeleton>
  </div>

  <!-- Top Box for Column 2 -->
  <div class="top-box">
    <ejs-skeleton shape="Rectangle" width="80%" height="50%"></ejs-skeleton>
  </div>

  <!-- Top Box for Column 3 -->
  <div class="top-box">
    <ejs-skeleton shape="Rectangle" width="80%" height="50%"></ejs-skeleton>
  </div>

  <!-- Top Box for Column 4 -->
  <div class="top-box">
    <ejs-skeleton shape="Rectangle" width="80%" height="50%"></ejs-skeleton>
  </div>

  <!-- Top Box for Column 5 -->
  <div class="top-box">
    <ejs-skeleton shape="Rectangle" width="80%" height="50%"></ejs-skeleton>
  </div>

  <!-- Top Box for Column 6 -->
  <div class="top-box">
    <ejs-skeleton shape="Rectangle" width="80%" height="50%"></ejs-skeleton>
  </div>
</div>

<div class="control-section">
  <!-- Column 1 -->
  <div class="skeleton-column">
    <ejs-skeleton class="skeleton-header" width="50%" height="20px"></ejs-skeleton>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
  </div>

  <!-- Column 2 -->
  <div class="skeleton-column">
    <ejs-skeleton class="skeleton-header" width="50%" height="20px"></ejs-skeleton>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
  </div>

  <!-- Column 3 -->
  <div class="skeleton-column">
    <ejs-skeleton class="skeleton-header" width="50%" height="20px"></ejs-skeleton>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
  </div>

  <!-- Column 4 -->
  <div class="skeleton-column">
    <ejs-skeleton class="skeleton-header" width="50%" height="20px"></ejs-skeleton>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
  </div>

  <!-- COlumn 5 -->
  <div class="skeleton-column">
    <ejs-skeleton class="skeleton-header" width="50%" height="20px"></ejs-skeleton>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
  </div>

  <!-- Column 6 -->
  <div class="skeleton-column">
    <ejs-skeleton class="skeleton-header" width="50%" height="20px"></ejs-skeleton>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
    <div class="skeleton-card">
      <ejs-skeleton class="skeleton-text" width="80%" height="15px"></ejs-skeleton>
      <ejs-skeleton class="skeleton-avatar" shape="Circle" width="40px" height="40px"></ejs-skeleton>
    </div>
  </div>
</div>
</div>
<!-- Skeleton end -->
