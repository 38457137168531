import { Component, OnInit } from '@angular/core';
import { AdminService } from '../utils/services/admin.service';
import { Router } from '@angular/router';
import { AgentsService } from '../utils/services/agents.service';
import { DialogService } from '../dialogservice.service';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-open-tasks',
  templateUrl: './open-tasks.component.html',
  styleUrl: './open-tasks.component.scss',
})
export class OpenTasksComponent implements OnInit {
  currentDisplayAgents: any[] = [];
  currentDisplayStatus: string = 'AgentCreatedFilesMissing';
  currentTitle: string = 'Neue Bewerber';

  newApplicantsAgent: any[] = [];
  newApplicantsCount = 0;

  newVoiceFileAgents: any[] = [];
  newVoiceFileCount = 0;

  appointmentScheduledAgents: any[] = [];
  appointmentScheduledCount = 0;

  applicatAcceptedAgents: any[] = [];
  applicatAcceptedAgentsCount = 0;

  applicationWithdrawnAgents: any[] = [];
  aapplicationWithdrawnAgentsCount = 0;

  unreadMessages: any[] = [];
  unreadMessagesCount = 0;

  isSpinnerActive: boolean = false;

  public data: any = {};

  sub?: Subscription;

  constructor(
    private adminService: AdminService,
    private agentsService: AgentsService,
    public router: Router,
    public dialogService: DialogService,
    private socket: Socket,
  ) {
  }

  ngOnInit(): void {
    this.isSpinnerActive = true;

    // Neue Bewerber
    this.adminService
      .getAgentsGroupedByProject('AgentCreatedFilesMissing')
      .subscribe((newApplicants) => {
        this.currentDisplayAgents = newApplicants;
        this.newApplicantsAgent = newApplicants;
        this.newApplicantsCount = this.newApplicantsAgent.reduce(
          (total, project) => total + project.agents.length,
          0
        );
        this.setProfileImageUrl(this.newApplicantsAgent);
        this.isSpinnerActive = false;
      });

    // Ungeprüfte Voicefiles
    this.adminService
      .getAgentsGroupedByProject('WaitingForAgent')
      .subscribe((newApplicants) => {
        this.newVoiceFileAgents = newApplicants;
        this.newVoiceFileCount = this.newVoiceFileAgents.reduce(
          (total, project) => total + project.agents.length,
          0
        );
        this.setProfileImageUrl(this.newVoiceFileAgents);
      });

    // Termine vereinbaren
    this.adminService
      .getAgentsGroupedByProject('AppointmentScheduled')
      .subscribe((newApplicants) => {
        this.appointmentScheduledAgents = newApplicants;
        this.appointmentScheduledCount = this.appointmentScheduledAgents.reduce(
          (total, project) => total + project.agents.length,
          0
        );
        this.setProfileImageUrl(this.appointmentScheduledAgents);
      });

    // Agent Zusage einholen
    this.adminService
      .getAgentsGroupedByProject('ApplicantAccepted')
      .subscribe((newApplicants) => {
        this.applicatAcceptedAgents = newApplicants;
        this.applicatAcceptedAgentsCount = this.applicatAcceptedAgents.reduce(
          (total, project) => total + project.agents.length,
          0
        );
        this.setProfileImageUrl(this.applicatAcceptedAgents);
      });

    // Zurückgezogene Bewerbungen
    this.adminService
    .getWithdrawAgentsGroupedByProject()
    .subscribe((newApplicants: any) => {
      this.applicationWithdrawnAgents = newApplicants;
      this.aapplicationWithdrawnAgentsCount = this.applicationWithdrawnAgents.reduce(
        (total, project) => total + project.agents.length,
        0
      );
      this.setProfileImageUrl(this.applicationWithdrawnAgents);
    });

    // Ungelesene Nachrichten
    this.adminService
      .getAgentsWithUnreadMessages()
      .subscribe((groupedAgents: any) => {
        this.unreadMessages = groupedAgents;
        this.unreadMessagesCount = this.unreadMessages.reduce(
          (total, project) => total + project.agents.length,
          0
        );
        this.setProfileImageUrl(this.unreadMessages);
      });
    
    this.sub = this.socket.fromEvent('unreadMessagesCount').subscribe((data: any) => {
      this.adminService
      .getAgentsWithUnreadMessages()
      .subscribe((groupedAgents: any) => {
        this.unreadMessages = groupedAgents;
        this.unreadMessagesCount = this.unreadMessages.reduce(
          (total, project) => total + project.agents.length,
          0
        );
        this.setProfileImageUrl(this.unreadMessages);
      });
    });
  }

  changeSelection(status: string) {
    switch (status) {
      case 'AgentCreatedFilesMissing':
        this.currentDisplayAgents = this.newApplicantsAgent;
        this.currentDisplayStatus = 'AgentCreatedFilesMissing';
        this.currentTitle = 'Neue Bewerber';
        break;
      case 'ApplicantGetsSuggested':
        this.currentDisplayAgents = this.newVoiceFileAgents;
        this.currentDisplayStatus = 'ApplicantGetsSuggested';
        this.currentTitle = 'Ungeprüfte Voicefiles';
        break;
      case 'AppointmentScheduled':
        this.currentDisplayAgents = this.appointmentScheduledAgents;
        this.currentDisplayStatus = 'AppointmentScheduled';
        this.currentTitle = 'Termine vereinbaren';
        break;
      case 'ApplicantAccepted':
        this.currentDisplayAgents = this.applicatAcceptedAgents;
        this.currentDisplayStatus = 'ApplicantAccepted';
        this.currentTitle = 'Agent Zusage einholen';
        break;
      case 'ApplicantWithdraw':
        this.currentDisplayAgents = this.applicationWithdrawnAgents;
        this.currentDisplayStatus = 'ApplicantWithdraw';
        this.currentTitle = 'Zurückgezogene Bewerbungen';
        break;
      case 'UnreadMessages':
        this.currentDisplayAgents = this.unreadMessages;
        this.currentDisplayStatus = 'UnreadMessages';
        this.currentTitle = 'Ungelesene Nachrichten';
    }
  }

  onClickAgent(agent: any) {
    if (this.currentDisplayStatus == 'ApplicantWithdraw'){
      this.data = {
        agentId: agent.id,
        projectId: agent.project.id,
        coloumnName: agent.status,
      };
      this.dialogService.showAgentView = true;
    } else {
      this.router.navigate([`/singleProjectView/${agent.project.id}`]);
    }
  }

  setProfileImageUrl(projects: any) {
    projects.forEach((project: any) => {
      project.agents.forEach((agent: any) => {
        if (agent.agentProfilePhoto) {
          agent.profileImageSrc = this.agentsService.convertImagetoUrl(
            agent.agentProfilePhoto.photoBlob
          );
        } else {
          agent.profileImageSrc = null;
        }
      });
    });
  }

  onMoveAgent() {
    const index = this.applicationWithdrawnAgents.findIndex(
      (project) => this.data.projectId == project.id
    );
    this.applicationWithdrawnAgents[index].agents =
      this.applicationWithdrawnAgents[index].agents.filter(
        (agent: any) => agent.id != this.data.agentId
      );

      this.aapplicationWithdrawnAgentsCount = this.applicationWithdrawnAgents.reduce(
        (total, project) => total + project.agents.length,
        0
      );
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
