import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export type Slot = { index: number, entry: any };

export function createSlots(count: number): Slot[] {
  const slots: Slot[] = [];
  for (let i = 0; i < count; i++) {
    slots.push({ index: i, entry: undefined });
  }
  return slots;
}

@Component({
  selector: 'drag-and-drop-slot-list',
  templateUrl: './drag-and-drop-slot-list.component.html',
  styleUrls: ['./drag-and-drop-slot-list.component.scss']
})
export class DragAndDropSlotListComponent {
  @Input()
  public id: string;

  @Input()
  public headerText: string;

  @Input()
  public count: string;
  
  @Input()
  public slots: Slot[];

  @ContentChild('cardTemplate', { static: true })
  public cardTemplate!: TemplateRef<any>;

  @Output()
  public dropEntry: EventEmitter<any> = new EventEmitter<any>();

  public constructor() {
    this.id = '';
    this.headerText = '';
    this.count = '0';
    this.slots = [];
  }

  public onDragStart(event: any, slot: Slot): void {
    const eventData = {
      id: this.id,
      data: slot
    };
    event.dataTransfer.setData('cards-entry', JSON.stringify(eventData));
  }

  public allowDrop(event: any): void {
    event.preventDefault();
  }
  
  public onDrop(event: any, index: number): void {
    event.preventDefault();

    const eventData = event.dataTransfer.getData('cards-entry');
    if (!eventData) { return; }

    const parsedEventData = JSON.parse(eventData);
    if (!parsedEventData) { return; }

    if (!this.dropEntry) { return; }
    this.dropEntry.emit({
      id: parsedEventData.id,
      data: {
        index: index,
        entry: parsedEventData.data.index !== undefined && parsedEventData.data.entry !== undefined ? parsedEventData.data.entry : parsedEventData.data
      }
    });
  }

  public getBackgroundColorClass(): string {
    const count = parseInt(this.count, 10);
    const occupied = this.slots.filter(slot => slot.entry !== undefined).length;

    if (occupied < count - 1) {
      return 'red-background';
    } else if (occupied < count) {
      return 'yellow-background';
    } else {
      return 'green-background';
    }
  }
}
