import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';

import { DialogService } from '../../dialogservice.service';
import { Customer } from 'src/app/utils/models/customer.model';
import { AdminService } from 'src/app/utils/services/admin.service';
import { CustomersService } from 'src/app/utils/services/customers.service';

@Component({
  selector: 'new-customer-assignment',
  templateUrl: './new-customer-assignment.component.html',
  styleUrl: './new-customer-assignment.component.scss',
})
export class NewCustomerAssignmentComponent implements OnInit {
  public users: any = [];
  public customers: string[] = [];
  public createNewCustomer: boolean = false;

  public customerForm: FormGroup;

  @Input() public selectedCustomer?: Customer;
  @Input() public isForUpdate: boolean = false;

  @Output() public changeinCustomer: EventEmitter<Customer> = new EventEmitter<Customer>();

  public constructor(
    private readonly adminService: AdminService,
    private readonly customersService: CustomersService,
    public dialogService: DialogService,
    private fb: FormBuilder
  ) {
    this.customerForm = this.fb.group({
      userId: ['', [Validators.required]],
      customerName: ['', [Validators.required]],
    });
  }

  public ngOnInit(): void {
    this.adminService.getAllKeyCloakUserGroupVise('Clients').subscribe((users: any) => {
      this.users = users;
    });

    this.customersService.findAll().subscribe((customers: Customer[]) => {
      this.customers = customers.map((customer: Customer) => customer.customerName);
      this.customers = [...new Set(this.customers)];
    });

    if (this.isForUpdate) {
      this.customerForm.patchValue({
        userId: this.selectedCustomer?.userId,
        customerName: this.selectedCustomer?.customerName,
      });
      this.customerForm.updateValueAndValidity();
    }
  }

  public closeDailog(): void {
    this.dialogService.showNewCustomerDialog = false;
  }

  public onSubmit(form: FormGroup): void {
    if (form.invalid) {
      return;
    }

    const customer: Customer = {
      id: this.isForUpdate ? this.selectedCustomer?.id! : uuid(),
      userId: form.value.userId,
      customerName: form.value.customerName,
    };

    this.changeinCustomer.emit(customer);
  }

  public toggleCreateNewCustomer(): void {
    this.createNewCustomer = !this.createNewCustomer;
  }
}
