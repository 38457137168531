import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AgentsService } from '../utils/services/agents.service';
import { AgentStatus } from '../utils/models/agent.model';
import { DialogService } from '../dialogservice.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import { KeycloakService } from 'keycloak-angular';
import { SharedService } from '../utils/services/shared.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-archived-agents',
  templateUrl: './archived-agents.component.html',
  styleUrl: './archived-agents.component.scss',
})
export class ArchivedAgentsComponent implements OnInit {
  archivedAgents: any = [];
  filteredAgents: any = [];
  public data: any = {};
  search = new FormControl('');
  isAllAgent: boolean = false;
  partnerId: string = '';
  allAgents: any = [];
  uniquePartnerIdArray: any = [];
  isSpinnerActive: any = false;

  constructor(
    private agentService: AgentsService,
    public dialogService: DialogService,
    private keyCloak: KeycloakService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    const status = AgentStatus.ApplicantArchived;
    this.isSpinnerActive = true;
    this.agentService.getAgentByStatus(status).subscribe((res) => {
      this.archivedAgents = res;
      this.archivedAgents.forEach((agent: any) => {
        const { project, status, createdAt, agentProfilePhoto } = agent;

        agent.projectName = project ? project.name : '';
        agent.statusName = this.sharedService.statuses[status].display;
        agent.createdAt = moment(createdAt).format('DD.MM.YYYY');
        agent.profileImageSrc = agentProfilePhoto
          ? this.agentService.convertImagetoUrl(agentProfilePhoto.photoBlob)
          : null;
      });
      this.filteredAgents = this.archivedAgents;
      this.isSpinnerActive = false;
      this.getAllAgent();
    });

    // const tokenData = this.keyCloak.getKeycloakInstance().tokenParsed;
    // const userRoles = tokenData?.realm_access?.roles;
    // if (userRoles?.includes('Partner')) {
    //   this.partnerId = tokenData?.['sub'] || '';
    // } else {
    //   this.partnerId = '';
    // }

    this.search.valueChanges.pipe(debounceTime(300)).subscribe((newValue: string | null) => this.filterAgentsByValue(newValue));
  }

  public filterAgentsByValue(newValue: string | null): void {
    if (this.isAllAgent) {
      if (newValue && newValue !== '') {
        this.filteredAgents = this.filterAgents(this.allAgents, newValue);
      } else {
        this.filteredAgents = [...this.allAgents];
      }
    } else {
      if (newValue && newValue !== '') {
        this.filteredAgents = this.filterAgents(this.archivedAgents, newValue);
      } else {
        this.filteredAgents = [...this.archivedAgents];
      }
    }
  }

  onClickAgent(agent: any) {
    this.data = {
      agentId: agent.id,
      projectId: agent.project?.id || null,
      coloumnName: agent.status,
    };
    this.dialogService.showAgentView = true;
  }

  onClose(event: any) {
    this.archivedAgents = this.archivedAgents.filter(
      (agent: any) => agent.id != this.data.agentId
    );
    this.filteredAgents = this.archivedAgents;
  }

  filterAgents(agentArray: any[], searchTerm: string): any[] {
    return agentArray.filter((agent: any) => {
      const excludedFields = [
        'profileImageSrc',
        'voiceFiles',
        'videoFiles',
        'agentProfilePhoto',
        'internalNote',
        'project',
        'updatedAt',
        'partnerId',
      ];

      const fullName = `${agent.firstname} ${agent.lastname}`.toLowerCase();
      const searchLower = searchTerm.toLowerCase();

      if (fullName.includes(searchLower)) {
        return true;
      }

      return Object.keys(agent).some((key) => {
        if (!excludedFields.includes(key)) {
          const value = agent[key];
          if (typeof value === 'string') {
            return value.toLowerCase().includes(searchLower);
          }
        }
        return false;
      });
    });
  }

  public onToggleSwitch(): void {
    this.search.setValue(this.search.value);
  }

  fetchPartnerInfo() {
    const partnerObservables = this.uniquePartnerIdArray.map((partnerId: any) =>
      this.agentService.getPartnerInfo(partnerId)
    );
    forkJoin(partnerObservables).subscribe({
      next: (partnerInfos: any) => {
        const partnerInfoMap: any = {};

        // Map partnerId to its fetched info
        this.uniquePartnerIdArray.forEach((id: any, index: any) => {
          partnerInfoMap[id] = partnerInfos[index];
        });

        this.allAgents = this.allAgents.map((item: any) => ({
          ...item,
          partnerLastName: partnerInfoMap[item.partnerId]?.lastName,
          partnerFirstName: partnerInfoMap[item.partnerId]?.firstName,
        }));
      },
      error: (error: any) => {
        console.error('Error fetching partner info:', error);
      },
    });
  }

  getAllAgent() {
    this.agentService.getAllAgentsByUserRole('').subscribe((res) => {
      this.allAgents = res;
      this.allAgents.forEach((agent: any) => {
        agent.projectName = agent.project ? agent.project.name : '';
        agent.statusName = this.sharedService.statuses[agent.status].display;
        agent.createdAt = moment(agent.createdAt).format('DD.MM.YYYY');
        if (agent.agentProfilePhoto) {
          agent.profileImageSrc = this.agentService.convertImagetoUrl(
            agent.agentProfilePhoto.photoBlob
          );
        } else {
          agent.profileImageSrc = null;
        }
        if (!this.uniquePartnerIdArray.includes(agent.partnerId)) {
          this.uniquePartnerIdArray.push(agent.partnerId);
        }
      });
      this.fetchPartnerInfo();
      // this.filteredAgents = this.allAgents;
    });
  }
}
