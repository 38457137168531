import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppComponent } from './app.component'
import { LoginComponent } from './login/login.component'
import { MainPageComponent } from './mainpage/mainpage.component'
import { ProjektübersichtComponent } from './projektübersicht/projektübersicht.component'
import { SingleProjectViewComponent } from './singleProjectView/singleProjectView.component'
import { KeycloakGuard } from './utils/guards/keycloak.guard'
import { AgentviewComponent } from './agentview/agentview.component'
import { TimeSlotsDialogComponent } from './timeSlotsDialog/timeSlotsDialog.component'
import { OpenTasksComponent } from './open-tasks/open-tasks.component'
import { ArchivedAgentsComponent } from './archived-agents/archived-agents.component'
import { UserManagementComponent } from './user-management/user-management.component'
import { LogoutComponent } from './logout/logout.component'

const routes: Routes = [
  { path: '', canActivate: [KeycloakGuard], component: AppComponent },
  // { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '', component: MainPageComponent, children: [
		{ path: 'projectoverview', canActivate: [KeycloakGuard], component: ProjektübersichtComponent },
		{ path: 'singleProjectView/:id', canActivate: [KeycloakGuard], component: SingleProjectViewComponent },
		{ path: 'agentView/:id', canActivate: [KeycloakGuard], component: AgentviewComponent },
		{ path: 'agentView', canActivate: [KeycloakGuard], component: AgentviewComponent },
		{ path: 'public/agentView/:publicId', component: AgentviewComponent },
    { path: 'timeslotmodal', canActivate: [KeycloakGuard], component: TimeSlotsDialogComponent },
    { path: 'open-tasks', canActivate: [KeycloakGuard], component: OpenTasksComponent },
    { path: 'archived-agents', canActivate: [KeycloakGuard], component: ArchivedAgentsComponent },
    { path: 'user-management', canActivate: [KeycloakGuard], component: UserManagementComponent }
	]},
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
