import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ConfigService } from '../../config.service';
import { Partner } from '../models/partner.model';
import { AdminService } from './admin.service';
import { DialogService } from 'src/app/dialogservice.service';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {
  private apiUrl = '';

  public constructor(
    private readonly configService: ConfigService,
    private readonly http: HttpClient,
    private readonly adminService: AdminService,
    public readonly dialogService: DialogService,
    private readonly snackBar: MatSnackBar
  ) {
    this.apiUrl = `${this.configService.baseUrl}/partners`;
  }

  public findAll() {
    return this.http.get<Partner[]>(this.apiUrl);
  }

  public findOne(id: string) {
    return this.http.get<Partner>(`${this.apiUrl}/${id}`);
  }

  public create(partner: Partner) {
    return this.http.post<Partner>(this.apiUrl, partner);
  }

  public update(partner: Partner) {
    return this.http.put<Partner>(`${this.apiUrl}/${partner.id}`, partner);
  }

  public delete(id: string) {
    return this.http.delete(`${this.apiUrl}/${id}`);
  }

  // Functionality

  public users: any = [];
  public partners: Partner[] = [];
  public partnerAssignments: { partner: Partner, user: any }[] = [];
  public partnerNames: string[] = [];

  public isForUpdate: boolean = false;

  public change(event: any): void {
    this.dialogService.showNewPartnerDialog = false;

    console.log('Change for Partners', event, this.isForUpdate);

    if (this.isForUpdate) {
      this.update(event).subscribe((partner: Partner) => {
        const index = this.partners.findIndex((p: Partner) => p.id === partner.id);
        this.partners[index] = partner;
        this.fetchAll(() => {});
      });
    } else {
      this.create(event).subscribe({
        next: (partner: Partner) => {
          this.partners.push(partner);
          this.fetchAll(() => {});
        },
        error: (error: any) => {
          let message = 'Partner kann nicht erstellt werden';

          this.snackBar.open(message, '', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        }
      });
    }
  }

  public fetchAll(fn: () => void): void {
    this.adminService.getAllKeyCloakUserGroupVise('Partners').subscribe((users: any) => {
      this.users = users;

      this.findAll().subscribe((partners: Partner[]) => {
        this.partners = partners;
        this.recreate();

        fn();
      });
    });
  }

  private recreate(): void {
    this.partnerAssignments = this.partners.map((partner: Partner) => {
      const assignedUser = this.users.find((user: any) => user.id === partner.userId);
      return {
        partner: partner,
        user: assignedUser
      };
    });

    this.partnerAssignments.sort((a, b) => {
      if (a.partner.partnerName < b.partner.partnerName) {
        return -1;
      }
      if (a.partner.partnerName > b.partner.partnerName) {
        return 1;
      }
      return 0;
    });

    this.partnerNames = this.partnerAssignments.map((pa) => pa.partner.partnerName);
    this.partnerNames = this.partnerNames.filter((value, index, self) => self.indexOf(value) === index);
  }
}
