import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';

import { DialogService } from '../../dialogservice.service';
import { Partner } from 'src/app/utils/models/partner.model';
import { AdminService } from 'src/app/utils/services/admin.service';
import { PartnersService } from 'src/app/utils/services/partners.service';

@Component({
  selector: 'new-partner-assignment',
  templateUrl: './new-partner-assignment.component.html',
  styleUrl: './new-partner-assignment.component.scss',
})
export class NewPartnerAssignmentComponent implements OnInit {
  public users: any = [];
  public partners: string[] = [];
  public createNewPartner: boolean = false;

  public partnerForm: FormGroup;

  @Input() public selectedPartner?: Partner;
  @Input() public isForUpdate: boolean = false;

  @Output() public changeinPartner: EventEmitter<Partner> = new EventEmitter<Partner>();

  public constructor(
    private readonly adminService: AdminService,
    private readonly partnersService: PartnersService,
    public dialogService: DialogService,
    private fb: FormBuilder
  ) {
    this.partnerForm = this.fb.group({
      userId: ['', [Validators.required]],
      partnerName: ['', [Validators.required]],
    });
  }

  public ngOnInit(): void {
    this.adminService.getAllKeyCloakUserGroupVise('Partners').subscribe((users: any) => {
      this.users = users;
    });

    this.partnersService.findAll().subscribe((partners: Partner[]) => {
      this.partners = partners.map((partner: Partner) => partner.partnerName);
      this.partners = [...new Set(this.partners)];
    });

    if (this.isForUpdate) {
      this.partnerForm.patchValue({
        userId: this.selectedPartner?.userId,
        partnerName: this.selectedPartner?.partnerName,
      });
      this.partnerForm.updateValueAndValidity();
    }
  }

  public closeDailog(): void {
    this.dialogService.showNewPartnerDialog = false;
  }

  public onSubmit(form: FormGroup): void {
    if (form.invalid) {
      return;
    }

    const partner: Partner = {
      id: this.isForUpdate ? this.selectedPartner?.id! : uuid(),
      userId: form.value.userId,
      partnerName: form.value.partnerName,
    };

    this.changeinPartner.emit(partner);
  }

  public toggleCreateNewPartner(): void {
    this.createNewPartner = !this.createNewPartner;
  }
}
