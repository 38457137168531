<div class="AgentView">
  <div class="close-btn ms-auto mb-3" style="margin-right: -8px;">
    <span class="d-block" style="color: rgb(102, 102, 102);" (click)="closeDailog()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
        <path
          d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z">
        </path>
      </svg>
    </span>
  </div>

  <form [formGroup]="partnerForm" (ngSubmit)="onSubmit(partnerForm)" class="w-100">
    <div class="projectContent d-flex justify-content-between align-items-start gap-3 overflow-y-auto">
      <div class="user-dsetails-wrapper w-100">

        <!-- ** SELECT USER ** -->
        <div class="d-flex align-items-center gap-2 mb-3">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path fill="currentColor"
                d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
              <circle cx="12" cy="8" r="5" fill="currentColor" />
            </svg>
            <p class="mb-0">Partner</p>
          </div>
          <div class="Layout234 user-input">
            <select aria-label="Default select example" class="form-select" formControlName="userId">
              <option *ngFor="let user of users" [value]="user.id">{{user.username}} ({{user.firstName}} {{user.lastName}})</option>
            </select>
          </div>
        </div>

        <!-- ** IS NEW PARTNER? ** -->
        <div class="d-flex align-items-center gap-2 mb-3">
          <div class="d-flex align-items-center gap-1 label">
            <p class="mb-0">Neuer Partner?</p>
          </div>
          <div class="Layout234 user-input">
            <input type="checkbox" (change)="toggleCreateNewPartner()">
          </div>
        </div>

        @if (createNewPartner) {
          <!-- ** ENTER PARTNER ** -->
          <div class="d-flex align-items-center gap-2 mb-3">
            <div class="d-flex align-items-center gap-1 label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path fill="currentColor"
                  d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
                <circle cx="12" cy="8" r="5" fill="currentColor" />
              </svg>
              <p class="mb-0">Partner</p>
            </div>
            <div class="user-input">
              <input type="text" name="partnerName" class="user-form-control" formControlName="partnerName">
            </div>
          </div>
        }

        @if (!createNewPartner) {
          <!-- ** SELECT PARTNER ** -->
          <div class="d-flex align-items-center gap-2 mb-3">
            <div class="d-flex align-items-center gap-1 label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path fill="currentColor"
                  d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
                <circle cx="12" cy="8" r="5" fill="currentColor" />
              </svg>
              <p class="mb-0">Partner</p>
            </div>
            <div class="Layout234 user-input">
              <select aria-label="Default select example" class="form-select" formControlName="partnerName">
                <option *ngFor="let partner of partners" [value]="partner">{{partner}}</option>
              </select>
            </div>
          </div>
        }

      </div>
    </div>

    <!-- BOTTOM ROW BUTTONS START -->
    <div class="bottomRow">

      <button #thisbutton_cssbutton type="button" class="cssButton" (click)="closeDailog()">
        <div class="focus:outline-0 bg-transparent">
          <span> Abbrechen </span>
        </div>
      </button>

      <button #thisbutton_cssbutton2 type="submit" class="cssButton2">
        <div class="focus:outline-0 bg-transparent">
          <span> {{isForUpdate?'Aktualisieren':'Speichern'}} </span>
        </div>
      </button>

    </div>
    <!-- BOTTOM ROW BUTTONS END -->
  </form>
</div>
