<div class="drag-and-drop-slot-list-container">
  <div
    class="drag-and-drop-slot-list-header"
    [ngClass]="getBackgroundColorClass()"
  >
    <div class="drag-and-drop-slot-list-header-text">
      <span>{{ headerText }}</span>
    </div>
    <div class="drag-and-drop-slot-list-count-container">
      <div class="drag-and-drop-slot-list-count">
        <span>{{ count }}</span>
      </div>
      <div class="drag-and-drop-slot-list-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 512 512"
        >
          <path
            fill="#00325d"
            d="M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38c-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6M432 480H80a31 31 0 0 1-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75c1.94 10.73-.68 21.34-7.18 29.11A31 31 0 0 1 432 480"
          />
        </svg>
      </div>
    </div>
  </div>

  <div class="drag-and-drop-slot-list" [ngClass]="getBackgroundColorClass()">
    <div
      *ngFor="let slot of slots; let i = index"
      class="drag-and-drop-slot"
      (dragover)="allowDrop($event)"
      (drop)="onDrop($event, i)"
    >
      <drag-and-drop-card
        *ngIf="i === slot.index && slot.entry"
        [data]="slot.entry"
        [cardTemplate]="cardTemplate"
        draggable="true"
        (dragstart)="onDragStart($event, slot)"
      ></drag-and-drop-card>
    </div>
  </div>
</div>