import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'

import { AppRoutingModule } from './app-routing.module'
import { MaterialModule } from './material.module'

import { TimeSlotsDialogComponent } from './timeSlotsDialog/timeSlotsDialog.component'
import { NewProjectComponent } from './newProject/newProject.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainPageComponent } from './mainpage/mainpage.component';
import { ProjektübersichtComponent } from './projektübersicht/projektübersicht.component';
import { DragAndDropCardComponent } from './singleProjectView/drag-and-drop-card/drag-and-drop-card.component';
import { DragAndDropSlotListComponent } from './singleProjectView/drag-and-drop-slot-list/drag-and-drop-slot-list.component';
import { DragAndDropCardListComponent } from './singleProjectView/drag-and-drop-card-list/drag-and-drop-card-list.component';
import { SingleProjectViewComponent } from './singleProjectView/singleProjectView.component';
import { AuthInterceptor } from './utils/interceptors/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment'
import { CreateAgentDialogComponent } from './singleProjectView/createAgentDialog/createAgentDialog.component'
import { AgentviewComponent } from './agentview/agentview.component'
import { CommonModule } from '@angular/common'
import { RecordComponent } from './record/record.component'
import { RecordingService } from './utils/services/recording.service'
import { VideoRecordingService } from './utils/services/video-recording.service'
import { VoicePlayerComponent } from "./voice-player/voice-player.component";
import { AgentWithdrawApplicationComponent } from "./dialogs/agent-withdraw-application/agent-withdraw-application.component";
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular'
import { EditorModule } from '@tinymce/tinymce-angular'
import { UserService } from './utils/services/user.service'
import { SocketIoModule } from 'ngx-socket-io';
import { ArchivedAgentsComponent } from './archived-agents/archived-agents.component'
import { MoveAgentComponent } from './move-agent/move-agent.component'
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { NewUserComponent } from './new-user/new-user.component'
import { UserManagementComponent } from './user-management/user-management.component'
import { OpenTasksComponent } from './open-tasks/open-tasks.component'

import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { SpinnerComponent } from './spinner/spinner.component'
import { SkeletonModule } from '@syncfusion/ej2-angular-notifications'
import { ConfigService } from './config.service'
import { CustomerManagementComponent } from './customer-management/customer-management.component'
import { NewCustomerAssignmentComponent } from './customer-management/new-customer-assignment/new-customer-assignment.component'
import { WhatsAppMessagesService } from './utils/services/whats-app-messages.service'

function initializeKeycloak(configService: ConfigService, keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: configService.config.keycloakUrl,
        realm: 'Smylo',
        clientId: configService.config.keycloakClient
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      shouldAddToken: (request) => {
        const acceptablePaths = ['/api/call'];
        const isAcceptablePathMatch = acceptablePaths.some((path) => request.url.includes(path));
        return !isAcceptablePathMatch;
      },
      loadUserProfileAtStartUp: true
    })
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainPageComponent,
    ProjektübersichtComponent,
    NewProjectComponent,
    SingleProjectViewComponent,
    DragAndDropCardComponent,
    DragAndDropCardListComponent,
    DragAndDropSlotListComponent,
    TimeSlotsDialogComponent,
    CreateAgentDialogComponent,
    AgentviewComponent,
    RecordComponent,
    VoicePlayerComponent,
    AgentWithdrawApplicationComponent,
    ArchivedAgentsComponent,
    MoveAgentComponent,
    NewUserComponent,
    UserManagementComponent,
    OpenTasksComponent,
    SpinnerComponent,
    CustomerManagementComponent,
    NewCustomerAssignmentComponent
  ],
  providers: [
    HttpClient,
    VideoRecordingService,
    RecordingService,
    UserService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [ConfigService, KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: 'tinymce/tinymce.min.js'
    },
    WhatsAppMessagesService
  ],
  bootstrap: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    KeycloakAngularModule,
    FormsModule,
    CommonModule,
    EditorModule,
    SocketIoModule.forRoot({ url: `${environment.protocol.endsWith('s') ? 'wss' : 'http'}://${environment.domain}${environment.domain === 'localhost' ? ':' + environment.port : ''}`, options: {} }),
    ReactiveFormsModule,
    TimePickerModule,
    DropDownListModule,
    DatePickerModule,
    SkeletonModule
  ]
})
export class AppModule {
}
